.system {
  width: 100%;
  background-color: #fff;
}

.description {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.beauty_industry_description {
  width: 100%;
  height: 8.64rem;
  padding: 0 2.2rem;
  background: linear-gradient( 126deg, rgba(0,100,255,1) 0%, rgba(91,156,255,1) 100%);
}

.beauty_industry_description_desc {
  width: 7.56rem;
  letter-spacing: 0.04rem;
}

.beauty_industry_description_tabs {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.beauty_industry_description_tabs_item {
  width: 2.17rem;
  height: 0.76rem;
  line-height: 0.76rem;
  box-shadow: 0.02rem -0.02rem 0.14rem 0 rgba(81,106,152,0.24);
  border-radius: 0.18rem;
  border: 0.02rem solid #FFFFFF;
}

.beauty_industry_description_tabs_item_active {
  background: #FFFFFF;
  box-shadow: 0.02rem -0.02rem 0.14rem 0 rgba(81,106,152,0.24);
  color: #005CFF;
}

.beauty_industry_description_tab {
  width: 14.8rem;
}

.beauty_industry_description_tab_card {
  width: 3.5rem;
  height: 3.81rem;
  background: #FFFFFF;
  border-radius: 0.18rem;
}

.card_header {
  display: flex;
  align-items: center;
}

.card_header_icon {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 0.3rem;
  overflow: hidden;
}

.card_title {
  width: 3.03rem;
  font-size: 0.17rem;
}

.modules {
  display: flex;
  align-items: center;
}

.module {
  width: 0.96rem;
  height: 0.4rem;
  line-height: 0.4rem;
  border-radius: 0.2rem;
}

.card_desc {
  width: 2.93rem;
  font-size: 0.17rem;
}

.product_module_item {
  width: 16.24rem;
}

.product_module_item_left {
  width: 6rem;
}

.product_module_item_title {
  display: flex;
  align-items: center;
}

.product_module_item_title_icon {
  width: 0.52rem;
  height: 0.52rem;
}

.product_module_item_right {
  width: 8rem;
  height: 5.28rem;
}

.product_module_item_buttons {
  display: flex;
  align-items: center;
}

.product_module_item_button_left,
.product_module_item_button_right {
  width: 1.6rem;
  height: 0.59rem;
  border-radius: 0.08rem;
  line-height: 0.59rem;
  cursor: pointer;
}

.product_module_item_button_left {
  background: linear-gradient( 90deg, #4588FF 0%, #206EF7 100%);
  box-shadow: 0.02rem 0 0.14rem 0 rgba(81,106,152,0.14);
  color: #FFFFFF;
}

.product_module_item_button_right {
  background: #FFFFFF;
  color: #005CFF;
  border: 0.01rem solid #005CFF;
}

.advertising_wrap {
  width: 100%;
  height: 1.54rem;
  background: url("../../static/home/advertising_bg.png") no-repeat;
  background-size: 100%;
  margin-top: 1.4rem;
}

.system .system-content .system-content-one {
  height: 15.67rem;
  text-align: center;
}

.system .system-content .system-content-one .name {
  height: 0.84rem;
  font-weight: 600;
  font-size: 0.6rem;
  color: #000000;
  line-height: 0.84rem;
  margin-top: 0.9rem;
  margin-bottom: 0.24rem;
}

.system .system-content .system-content-one .tip {
  height: 0.28rem;
  font-weight: 600;
  font-size: 0.2rem;
  color: rgba(0, 0, 0, 0.7);
  line-height: 0.28rem;
  text-align: center;
}

.system .system-content .system-content-one .system-function {
  height: 0.82rem;
  margin: 0.4rem auto;
  padding: 0 4.65rem;
}

.system .system-content .system-content-one .system-function .system-function-item {
  width: 0.8rem;
  text-align: center;
}

.system .system-content .system-content-one .system-function .system-function-item .system-function-img {
  width: 0.42rem;
  height: 0.42rem;
  margin-bottom: 0.12rem;
}

.system .system-content .system-content-one .system-function .system-function-item .system-function-name {
  height: 0.28rem;
  font-weight: 400;
  font-size: 0.2rem;
  color: rgba(0, 0, 0, 0.8);
  line-height: 0.28rem;
}

.system .system-content .system-content-one .system-content-introduce .one {
  width: 4.6rem;
  height: 11.47rem;
  background: #FFFFFF;
  box-shadow: 0 0.02rem 0.15rem 0 rgba(0,26,77,0.16);
  border-radius: 0.2rem 0.2rem 0 0;
  margin: 0 0.14rem;
}

.system .system-content .system-content-one .system-content-introduce .one .header {
  width: 4.6rem;
  height: 0.27rem;
  background: #FF3535;
  border-radius: 0.2rem 0.2rem 0 0;
}

.system .system-content .system-content-one .system-content-introduce .one .content .introduce-title {
  height: 0.7rem;
  font-weight: 600;
  font-size: 0.5rem;
  color: #000000;
  line-height: 0.7rem;
}

.system .system-content .system-content-one .system-content-introduce .one .content .introduce-tip {
  font-weight: 500;
  font-size: 0.24rem;
  color: #3D3D3D;
  line-height: 0.34rem;
  margin-bottom: 0.64rem;
}

.system .system-content .system-content-one .system-content-introduce .one .content .introduce-price {
  height: 1.12rem;
  font-weight: 500;
  font-size: 0.8rem;
  color: #000000;
  line-height: 1.12rem;
  margin-bottom: 0.27rem;
}

.system .system-content .system-content-one .system-content-introduce .one .content .introduce-price span {
  font-size: 0.4rem;
  font-weight: 300;
}

.system .system-content .system-content-one .system-content-introduce .one .content .introduce-function {
  padding: 0 0.5rem;
}

.system .system-content .system-content-one .system-content-introduce .one .content .introduce-function .introduce-item {
  margin-bottom: 0.44rem;
}

.system .system-content .system-content-one .system-content-introduce .one .content .introduce-function .introduce-item .introduce-img {
  width: 0.34rem;
  height: 0.34rem;
}

.system .system-content .system-content-one .system-content-introduce .one .content .introduce-function .introduce-item .introduce-name {
  text-align: left;
  width: 2.16rem;
  font-weight: 400;
  font-size: 0.24rem;
  color: #3D3D3D;
  line-height: 0.34rem;
  margin-left: 0.12rem;
}

.system .system-content .system-content-one .system-content-introduce .one .content .introduce-buttons {
  padding: 0 0.5rem;
}

.system .system-content .system-content-one .system-content-introduce .one .content .introduce-buttons .introduce-button {
  width: 1.6rem;
  height: 0.59rem;
  background: linear-gradient(90deg, #4588FF 0%, #206EF7 100%);
  box-shadow: 0.02rem 0 0.14rem 0 rgba(81, 106, 152, 0.14);
  border-radius: 0.08rem;
  text-align: center;
  font-weight: 500;
  font-size: 0.22rem;
  color: #FFFFFF;
  line-height: 0.59rem;
  cursor: pointer;
}

.system .system-content .system-content-one .system-content-introduce .one .content .introduce-buttons .button {
  background: #FFFFFF;
  color: #005CFF;
  border: 0.01rem solid #4588FF;
}

.system .system-content .system-content-one .system-content-introduce .two {
  width: 4.6rem;
  height: 11.47rem;
  background: #FFFFFF;
  box-shadow: 0 0.02rem 0.15rem 0 rgba(0,26,77,0.16);
  border-radius: 0.2rem 0.2rem 0 0;
  margin: 0 0.14rem;
}

.system .system-content .system-content-one .system-content-introduce .two .header {
  width: 4.6rem;
  height: 0.27rem;
  background: #FF7B29;
  border-radius: 0.2rem 0.2rem 0 0;
}

.system .system-content .system-content-one .system-content-introduce .two .content .introduce-title {
  height: 0.7rem;
  font-weight: 600;
  font-size: 0.5rem;
  color: #000000;
  line-height: 0.7rem;
}

.system .system-content .system-content-one .system-content-introduce .two .content .introduce-tip {
  font-weight: 500;
  font-size: 0.24rem;
  color: #3D3D3D;
  line-height: 0.34rem;
  margin-bottom: 0.3rem;
  padding: 0 0.5rem;
}

.system .system-content .system-content-one .system-content-introduce .two .content .introduce-price {
  height: 1.12rem;
  font-weight: 500;
  font-size: 0.8rem;
  color: #000000;
  line-height: 1.12rem;
  margin-bottom: 0.2rem;
}

.system .system-content .system-content-one .system-content-introduce .two .content .introduce-price span {
  font-size: 0.4rem;
  font-weight: 300;
}

.system .system-content .system-content-one .system-content-introduce .two .content .introduce-equity {
  width: 4.2rem;
  height: 0.68rem;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 0.12rem;
  text-align: center;
  font-weight: 400;
  font-size: 0.24rem;
  color: #387AF7;
  line-height: 0.68rem;
  margin: 0 auto;
  margin-bottom: 0.52rem;
}

.system .system-content .system-content-one .system-content-introduce .two .content .introduce-equity span {
  font-size: 0.28rem;
  font-weight: 600;
}

.system .system-content .system-content-one .system-content-introduce .two .content .introduce-function {
  padding: 0 0.25rem;
}

.system .system-content .system-content-one .system-content-introduce .two .content .introduce-function .introduce-item {
  margin-bottom: 0.44rem;
}

.system .system-content .system-content-one .system-content-introduce .two .content .introduce-function .introduce-item .introduce-img {
  width: 0.34rem;
  height: 0.34rem;
}

.system .system-content .system-content-one .system-content-introduce .two .content .introduce-function .introduce-item .introduce-name {
  text-align: left;
  font-weight: 400;
  font-size: 0.24rem;
  color: #3D3D3D;
  line-height: 0.34rem;
  margin-left: 0.12rem;
}

.system .system-content .system-content-one .system-content-introduce .two .content .introduce-buttons {
  padding: 0 0.5rem;
}

.system .system-content .system-content-one .system-content-introduce .two .content .introduce-buttons .introduce-button {
  width: 1.6rem;
  height: 0.59rem;
  background: linear-gradient(90deg, #4588FF 0%, #206EF7 100%);
  box-shadow: 0.02rem 0 0.14rem 0 rgba(81, 106, 152, 0.14);
  border-radius: 0.08rem;
  text-align: center;
  font-weight: 500;
  font-size: 0.22rem;
  color: #FFFFFF;
  line-height: 0.59rem;
  cursor: pointer;
}

.system .system-content .system-content-one .system-content-introduce .two .content .introduce-buttons .button {
  background: #FFFFFF;
  color: #005CFF;
  border: 0.01rem solid #4588FF;
}

.system .system-content .system-content-one .system-content-introduce .three {
  width: 4.6rem;
  height: 11.47rem;
  background: #FFFFFF;
  box-shadow: 0 0.02rem 0.15rem 0 rgba(0,26,77,0.16);
  border-radius: 0.2rem 0.2rem 0 0;
  margin: 0 0.14rem;
}

.system .system-content .system-content-one .system-content-introduce .three .header {
  width: 4.6rem;
  height: 0.27rem;
  background: #387AF7;
  border-radius: 0.2rem 0.2rem 0 0;
}

.system .system-content .system-content-one .system-content-introduce .three .content .introduce-title {
  height: 0.7rem;
  font-weight: 600;
  font-size: 0.5rem;
  color: #000000;
  line-height: 0.7rem;
}

.system .system-content .system-content-one .system-content-introduce .three .content .introduce-tip {
  font-weight: 500;
  font-size: 0.24rem;
  color: #3D3D3D;
  line-height: 0.34rem;
  margin-bottom: 0.56rem;
  padding: 0 0.8rem;
}

.system .system-content .system-content-one .system-content-introduce .three .content .introduce-price {
  width: 2.26rem;
  height: 0.59rem;
  background: linear-gradient(90deg, #4588FF 0%, #206EF7 100%);
  box-shadow: 0.02rem 0 0.14rem 0 rgba(81, 106, 152, 0.14);
  border-radius: 0.3rem;
  font-weight: 500;
  font-size: 0.22rem;
  color: #FFFFFF;
  line-height: 0.59rem;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.system .system-content .system-content-one .system-content-introduce .three .content .introduce-equity {
  width: 4.2rem;
  height: 0.68rem;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 0.12rem;
  text-align: center;
  font-weight: 400;
  font-size: 0.24rem;
  color: #387AF7;
  line-height: 0.68rem;
  margin: 0 auto;
  margin-bottom: 0.52rem;
}

.system .system-content .system-content-one .system-content-introduce .three .content .introduce-equity span {
  font-size: 0.28rem;
  font-weight: 600;
}

.system .system-content .system-content-one .system-content-introduce .three .content .introduce-function {
  padding: 0 0.25rem;
}

.system .system-content .system-content-one .system-content-introduce .three .content .introduce-function .introduce-item {
  margin-bottom: 0.44rem;
}

.system .system-content .system-content-one .system-content-introduce .three .content .introduce-function .introduce-item .introduce-img {
  width: 0.34rem;
  height: 0.34rem;
}

.system .system-content .system-content-one .system-content-introduce .three .content .introduce-function .introduce-item .introduce-name {
  text-align: left;
  font-weight: 400;
  font-size: 0.24rem;
  color: #3D3D3D;
  line-height: 0.34rem;
  margin-left: 0.12rem;
}

.system .system-content .system-content-two {
  padding: 0 2.4rem;
}

.system_content_two_top_header {
  width: 100%;
  height: 3.78rem;
  background: #FFFFFF;
  z-index: 999;
  box-shadow: 0 0.01rem 0.02rem 0 rgba(0, 0, 0, 0.03), 0 0.01rem 0.06rem -0.01rem rgba(0, 0, 0, 0.02), 0 0.02rem 0.04rem 0 rgba(0, 0, 0, 0.02);
  position: sticky;
  top: 0.9rem;
  padding: 0 2.4rem;
}

.system_content_two_top_header_card {
  height: 2.98rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.system_content_two_top_header_card .one {
  width: 2.9rem;
  height: 1.41rem;
  line-height: 1.41rem;
  background-color: #495273;
  border-radius: 0.2rem;
}

.system_content_two_top_header_card .two {
  width: 2.9rem;
}

.system_content_two_top_header_card .two .two-img {
  width: 0.64rem;
  height: 0.64rem;
}

.system_content_two_top_header_card .two .two-price {
  height: 0.49rem;
  line-height: 0.49rem;
  font-weight: 600;
  font-size: 0.5rem;
}

.system_content_two_top_header_card .two .two-price span {
  width: 0.3rem;
  font-size: 0.2rem;
  font-weight: 400;
}

.system_content_two_top_header_card .two .two-give {
  height: 0.36rem;
  font-weight: 400;
  font-size: 0.2rem;
  color: #595959;
  line-height: 0.36rem;
}

.header_card_buy_btn {
  width: 1.58rem;
  height: 0.57rem;
  line-height: 0.55rem;
  color: #005CFF;
  background: #FFFFFF;
  border-radius: 0.29rem;
  border: 0.01rem solid #005CFF;
  cursor: pointer;
  transition: all 0.2s;
}

.header_card_buy_btn_active {
  width: 2.26rem;
  background: linear-gradient( 90deg, #4588FF 0%, #206EF7 100%);
  box-shadow: 0.02rem 0 0.14rem 0 rgba(81,106,152,0.14);
  border-radius: 0.3rem;
  border: none;
  color: #ffffff;
}

.system .system-content .system-content-two .content {
  padding-bottom: 0.9rem;
  border-radius: 0.24rem;
  border: 0.02rem solid #DEEAFF;
}

.system .system-content .system-content-two .content .content-header {
  background-color: #ffffff;
  position: sticky;
  top: 0.9rem;
  z-index: 998;
}

.system .system-content .system-content-two .content .content-header .one {
  width: 2.9rem;
  height: 2.3rem;
  line-height: 2.3rem;
  background-color: #495273;
  border-radius: 0.2rem;
}

.system .system-content .system-content-two .content .content-header .two {
  width: 2.9rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.system .system-content .system-content-two .content .content-header .two .two-img {
  width: 0.64rem;
  height: 0.64rem;
}

.system .system-content .system-content-two .content .content-header .two .two-price {
  height: 0.49rem;
  line-height: 0.49rem;
  font-weight: 600;
  font-size: 0.5rem;
  margin: 0.14rem 0;
}

.system .system-content .system-content-two .content .content-header .two .two-price span {
  width: 0.3rem;
  font-size: 0.2rem;
  font-weight: 400;
}

.system .system-content .system-content-two .content .content-header .two .two-give {
  height: 0.36rem;
  font-weight: 400;
  font-size: 0.2rem;
  color: #595959;
  line-height: 0.36rem;
}

.function_module_table {
  display: flex;
  flex-direction: column;
}

.function_module_table_title {
  align-items: center;
}

.function_module_table_title_icon {
  width: 0.36rem;
  height: 0.36rem;
}

.function_module_table_body {
  display: flex;
  flex-direction: column;
}

.function_module_table_line {
  width: 100%;
  height: 0.7rem;
}

.function_module_table_line_name {
  width: 2.9rem;
}

.function_module_table_line_icon1,
.function_module_table_line_icon2,
.function_module_table_line_icon3 {
  width: 2.9rem;
  color: #3377FF;
}

.function_module_table_line_check_img {
  width: 0.33rem;
  height: 0.33rem;
  background: #3377FF;
  border-radius: 50%;
}

.version_selection_guide_item {
  width: 14.66rem;
  align-items: center;
}

.version_selection_guide_item_icon {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: #DEEAFF;
  overflow: hidden;
}

.version_selection_guide_item_content {
  max-width: 10.1rem;
  color: #333333;
  padding: 0.3rem;
  background: #FFFFFF;
  box-shadow: 0.02rem 0.04rem 0.15rem 0 rgba(0,0,0,0.15);
  border-radius: 0 0.2rem 0.2rem 0.2rem;
}

.version_selection_guide_item_content_right {
  color: #ffffff;
  background: linear-gradient( 90deg, #4588FF 0%, #206EF7 100%);
  box-shadow: 0.02rem 0.04rem 0.15rem 0 rgba(0,62,179,0.14);
  border-radius: 0.2rem 0 0.2rem 0.2rem;
}