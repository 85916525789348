view {
  box-sizing: border-box;
}

button {
  margin: 0;
  line-height: normal;
  background: transparent;
}

button::after {
  border: none;
}

.fff {
  background: #fff;
}

.center {
  text-align: center !important;
}

.initial {
  text-align: initial;
}

.pad_28 {
  padding: 0 0.28rem;
}

/* flex布局 */

.flex {
  display: flex;
}

.ju_bw {
  justify-content: space-between;
}

.ju_around {
  justify-content: space-around;
}

.ju_cen {
  justify-content: center;
}

.ali_center {
  align-items: center;
}

.flex_d {
  flex-direction: column;
}

/* 缺省样式 */

.noMore {
  width: 100%;
  height: 0.8rem;
  line-height: 0.8rem;
  text-align: center;
  font-size: 0.28rem;
  font-weight: 400;
  color: #777;
}

.empty {
  width: 100%;
  padding-top: 3rem;
  text-align: center;
}

.empty image {
  width: 2.2rem;
  height: 2.48rem;
}

.empty .cont {
  margin-top: 0.56rem;
  font-size: 0.28rem;
  font-weight: 400;
  color: #999;
  letter-spacing: 0.02rem;
}