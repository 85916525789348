.partner-settlement {
  background-color: #fff;
}

.partner_header {
  width: 100%;
  height: 6.85rem;
  background: #F0F5FF;
  display: flex;
  justify-content: center;
}

.partner_header_left {
  width: 5.4rem;
  margin-top: 1.7rem;
}

.partner_header_title {
  display: flex;
  align-items: center;
}

.partner_header_wx_icon {
  width: 0.64rem;
  height: 0.64rem;
}

.min_app {
  font-size: 0.4rem;
  color: #000000;
  margin-left: 0.12rem;
}

.partner_header_title_text {
  font-weight: 600;
  font-size: 0.46rem;
  color: #206EF8;
}

.partner_header_item {
  font-weight: 400;
  font-size: 0.25rem;
  color: #000000;
  display: flex;
  align-items: center;
}

.partner_header_item_icon {
  width: 0.21rem;
  height: 0.2rem;
}

.partner_header_item_text {
  margin-left: 0.12rem;
}

.partner_header_btn {
  width: 2.46rem;
  height: 0.74rem;
  line-height: 0.74rem;
  background: linear-gradient( 270deg, #4588FF 0%, #206EF7 100%);
  box-shadow: 0 0.07rem 0.14rem 0 rgba(0,66,187,0.25);
  border-radius: 0.37rem;
  font-size: 0.27rem;
  color: #FFFFFF;
  cursor: pointer;
}

.partner_header_img {
  width: 9.44rem;
  height: 5.9rem;
  margin-top: 0.73rem;
}

.partner_flow_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.partner_flow_title {
  font-size: 0.4rem;
  color: rgba(0,0,0,0.9);
}

.partner_flow_desc {
  font-weight: 400;
  font-size: 0.3rem;
  color: rgba(0,0,0,0.75);
}

.partner_flow_img {
  width: 16.5rem;
  height: 4.95rem;
}

.partner_content {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #F0F5FF;
  padding: 1rem 2rem;
}

.partner_content_img {
  width: 15.17rem;
  height: 17.58rem;
}

.partner_expert_img {
  width: 14.55rem;
  height: 4.29rem;
  display: block;
  margin: 0 auto;
  margin-top: 0.66rem;
  margin-bottom: 1.18rem;
}