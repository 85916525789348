.thirdpartnar {
  background: #ffffff;
}

.thirdpartnar_header {
  width: 100vw;
  height: 8.49rem;
  background-image: url('../../../static/images/thirdpartnar/third_partner_bg1.png');
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.free_get_form {
  width: 14rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  bottom: 0.2rem;
}

.free_get_form_input {
  width: 2.74rem;
  height: 0.63rem;
  font-size: 0.22rem;
  color: rgba(0,0,0,0.76);
  background: #FFF4ED;
  border-radius: 0.1rem;
  border: 0.02rem solid #FF7B00;
  padding-left: 0.2rem;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
}

.free_get_button {
  width: 5.88rem;
  height: 0.74rem;
  line-height: 0.74rem;
  background: linear-gradient( 90deg, #FF7F00 0%, #FF4800 100%), #007AFF;
  border-radius: 0.12rem;
  letter-spacing: 0.02rem;
  font-weight: 700;
  position: absolute;
  bottom: -0.8rem;
  cursor: pointer;
}

.third_img {
  width: 100%;
  height: auto;
}

.free_get__bottom_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.23rem 0 0.65rem  0;
}

.form_item {
  width: 5.88rem;
  height: 0.63rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.4rem;
  margin-top: 0.7rem;
  font-size: 0.22rem;
}

.free_get_form_item_input {
  width: 2.74rem;
  height: 0.63rem;
  background: #FFF4ED;
  border-radius: 0.1rem;
  border: 0.02rem solid #FF7B00;
  padding-left: 0.2rem;
}

.free_get_bottom_form_input {
  width: 5.88rem;
  height: 0.63rem;
  background: #FFF4ED;
  border-radius: 0.1rem;
  border: 0.02rem solid #FF7B00;
  appearance: none;
  font-size: 0.22rem;
  padding-left: 0.2rem;
  -moz-appearance: none;
  -webkit-appearance: none;
  margin-bottom: 0.4rem;
}

.bottom_form_btn {
  width: 5.88rem;
  height: 0.74rem;
  line-height: 0.74rem;
  background: linear-gradient( 90deg, #FF7F00 0%, #FF4800 100%), #007AFF;
  border-radius: 0.12rem;
  font-weight: 700;
  letter-spacing: 0.02rem;
}

.success_toast_wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.success_toast_card {
  width: 7.68rem;
  height: 5.43rem;
  background: #dddddd;
  border-radius: 0.25rem;
}

.success_toast_title {
  font-size: 0.28rem;
  color: #000000;
}

.success_toast_icon {
  width: 1.52rem;
  height: 1.42rem;
}

.success_toast_desc {
  font-size: 0.22rem;
  color: #000000;
}

.success_toast_close {
  width: 5.88rem;
  height: 0.74rem;
  line-height: 0.74rem;
  background: linear-gradient( 90deg, #FF7F00 0%, #FF4800 100%), #007AFF;
  border-radius: 0.12rem;
  font-weight: 700;
  font-size: 0.16rem;
  color: #FFFFFF;
  letter-spacing: 0.02rem;
  cursor: pointer;
}

.success_toast_hotline {
  width: 0.62rem;
  height: 0.62rem;
  border-radius: 50%;
  cursor: pointer;
}