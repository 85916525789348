.mobile_software_wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile_software_title {
  font-size: 0.65rem;
  font-weight: 600;
  letter-spacing: 0.02rem;
}

.mobile_software_title_desc {
  font-size: 0.4rem;
  letter-spacing: 0.03rem;
}

.free {
  width: 3rem;
  height: 1rem;
  color: #ffffff;
  font-size: 0.4rem;
  background: #e43535;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}

.mobile_software_download_card {
  width: 90vw;
  background-color: #ffffff;
  border-radius: 0.2rem;
  flex-wrap: wrap;
  padding: 0.3rem;
  margin-top: 1rem;
}

.mobile_software_download_card_item {
  margin: 0.25rem;
}

.mobile_software_download_product_img {
  width: 98vw;
  height: 8rem;
  margin-top: 1rem;
}

.mobile_software_more_buy_path_title {
  font-size: 0.5rem;
  letter-spacing: 0.03rem;
  margin-top: 0.3rem;
}

.mobile_software_more_buy_path_card {
  width: 96vw;
  background: rgba(255,255,255,0.2);
  border-radius: 0.2rem;
  border: 0.02rem solid #FFFFFF;
  padding: 0.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.3rem;
}

.mobile_software_more_buy_path_card_item {
  width: 45vw;
  height: 3rem;
  background-color: #ffffff;
  border-radius: 0.2rem;
  justify-content: center;
}

.mobile_software_more_buy_path_card_item_title {
  margin-top: 0.2rem;
}

.mobile_software_more_buy_path_card_item_btn {
  width: 2rem;
  height: 0.8rem;
  border-radius: 0.4rem;
  line-height: 0.8rem;
  color: #ffffff;
  background: linear-gradient( 90deg, #4588FF 0%, #206EF7 100%);
  margin-top: 0.2rem;
}