* {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

input {
  outline: none;
  border: 0;
}

p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #333;
}

a:link,
a:visited {
  text-decoration: none;
  /*超链接无下划线*/
}

img {
  border: 0;
  outline: none;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}