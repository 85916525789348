.scrollTop-box {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  position: fixed;
  bottom: 0.4rem;
  right: 0;
  box-shadow: 0 0.06rem 0.4rem 0 rgba(0,0,0,0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.scrollTop-box img {
  width: 0.3rem;
  height: 0.3rem;
}