/* 字体大小 */

.f0 {
  font-size: 0;
}

.f12 {
  font-size: 0.12rem;
}

.f14 {
  font-size: 0.14rem;
}

.f15 {
  font-size: 0.15rem;
}

.f16 {
  font-size: 0.16rem;
}

.f17 {
  font-size: 0.17rem;
}

.f18 {
  font-size: 0.18rem;
}

.f20 {
  font-size: 0.2rem;
}

.f22 {
  font-size: 0.22rem;
}

.f24 {
  font-size: 0.24rem !important;
}

.f25 {
  font-size: 0.25rem;
}

.f26 {
  font-size: 0.26rem;
}

.f28 {
  font-size: 0.28rem;
}

.f29 {
  font-size: 0.29rem;
}

.f30 {
  font-size: 0.3rem;
}

.f32 {
  font-size: 0.32rem !important;
}

.f34 {
  font-size: 0.34rem;
}

.f35 {
  font-size: 0.35rem;
}

.f36 {
  font-size: 0.36rem !important;
}

.f38 {
  font-size: 0.38rem !important;
}

.f40 {
  font-size: 0.4rem !important;
}

.f42 {
  font-size: 0.42rem;
}

.f44 {
  font-size: 0.44rem;
}

.f46 {
  font-size: 0.46rem;
}

.f48 {
  font-size: 0.48rem;
}

.f50 {
  font-size: 0.5rem;
}

.f55 {
  font-size: 0.55rem;
}

.f56 {
  font-size: 0.56rem;
}

.f60 {
  font-size: 0.6rem;
}

.fw400 {
  font-weight: 400 !important;
}

.fb {
  font-weight: bold;
}

.fn {
  font-weight: normal;
}

.t2 {
  text-indent: 2em;
}

.unl {
  text-decoration: underline;
}

.no_unl {
  text-decoration: none;
}

.no_outline {
  outline: none;
}

.middle_line {
  color: #959595;
  text-decoration: line-through;
}

/* 字体定位 */

.tl {
  text-align: left !important;
}

.tc {
  text-align: center !important;
}

.tr {
  text-align: right !important;
}

.fl {
  float: left;
}

.fr {
  float: right;
}

.pr {
  position: relative;
}

.par {
  position: absolute;
}

.ver_top {
  vertical-align: top;
}

.ver_mid {
  vertical-align: middle;
}

.ver_bom {
  vertical-align: bottom;
}

.indent {
  text-indent: 2em;
}

/* 清除浮动*/

.clear:before,
.clear:after {
  content: "";
  display: table;
  line-height: 0;
}

.clear:after {
  clear: both;
}

.clear {
  zoom: 1;
}

/* 间距 */

.mar_center {
  margin: 0 auto;
}

.m10 {
  margin: 0.1rem;
}

.m15 {
  margin: 0.15rem;
}

.m20 {
  margin: 0.2rem;
}

.m25 {
  margin: 0.25rem;
}

.m30 {
  margin: 0.3rem;
}

.mt-3 {
  margin-top: -0.03rem;
}

.mt5 {
  margin-top: 0.05rem;
}

.mt6 {
  margin-top: 0.06rem;
}

.mt7 {
  margin-top: 0.07rem;
}

.mt8 {
  margin-top: 0.08rem;
}

.mt10 {
  margin-top: 0.1rem;
}

.mt12 {
  margin-top: 0.12rem;
}

.mt15 {
  margin-top: 0.15rem;
}

.mt17 {
  margin-top: 0.17rem;
}

.mt18 {
  margin-top: 0.18rem;
}

.mt20 {
  margin-top: 0.2rem;
}

.mt22 {
  margin-top: 0.22rem;
}

.mt24 {
  margin-top: 0.24rem;
}

.mt25 {
  margin-top: 0.25rem;
}

.mt26 {
  margin-top: 0.26rem;
}

.mt28 {
  margin-top: 0.28rem;
}

.mt30 {
  margin-top: 0.3rem;
}

.mt32 {
  margin-top: 0.32rem;
}

.mt36 {
  margin-top: 0.36rem;
}

.mt40 {
  margin-top: 0.4rem;
}

.mt44 {
  margin-top: 0.44rem;
}

.mt50 {
  margin-top: 0.5rem;
}

.mt53 {
  margin-top: 0.53rem;
}

.mt60 {
  margin-top: 0.6rem;
}

.mt61 {
  margin-top: 0.61rem;
}

.mt62 {
  margin-top: 0.62rem;
}

.mt63 {
  margin-top: 0.63rem;
}

.mt64 {
  margin-top: 0.64rem;
}

.mt65 {
  margin-top: 0.65rem;
}

.mt67 {
  margin-top: 0.67rem;
}

.mt70 {
  margin-top: 0.7rem;
}

.mt74 {
  margin-top: 0.74rem;
}

.mt75 {
  margin-top: 0.75rem;
}

.mt80 {
  margin-top: 0.8rem;
}

.mt88 {
  margin-top: 0.88rem;
}

.mt90 {
  margin-top: 0.9rem;
}

.mt100 {
  margin-top: 1rem;
}

.mt105 {
  margin-top: 1.05rem;
}

.mt110 {
  margin-top: 1.1rem;
}

.mt114 {
  margin-top: 1.14rem;
}

.mt120 {
  margin-top: 1.2rem;
}

.mt140 {
  margin-top: 1.4rem;
}

.mt174 {
  margin-top: 1.74rem;
}

.mt184 {
  margin-top: 1.84rem;
}

.mb5 {
  margin-bottom: 0.05rem;
}

.mb10 {
  margin-bottom: 0.1rem;
}

.mb15 {
  margin-bottom: 0.15rem;
}

.mb18 {
  margin-bottom: 0.18rem;
}

.mb20 {
  margin-bottom: 0.2rem;
}

.mb24 {
  margin-bottom: 0.24rem;
}

.mb25 {
  margin-bottom: 0.25rem;
}

.mb30 {
  margin-bottom: 0.3rem;
}

.mb32 {
  margin-bottom: 0.32rem;
}

.mb40 {
  margin-bottom: 0.4rem;
}

.mb50 {
  margin-bottom: 0.5rem;
}

.mb60 {
  margin-bottom: 0.6rem;
}

.mb70 {
  margin-bottom: 0.7rem;
}

.mb100 {
  margin-bottom: 1rem;
}

.mb160 {
  margin-bottom: 1.6rem;
}

.mr5 {
  margin-right: 0.05rem;
}

.mr7 {
  margin-right: 0.05rem;
}

.mr8 {
  margin-right: 0.08rem;
}

.mr10 {
  margin-right: 0.1rem;
}

.mr12 {
  margin-right: 0.12rem;
}

.mr15 {
  margin-right: 0.15rem;
}

.mr20 {
  margin-right: 0.2rem;
}

.mr24 {
  margin-right: 0.24rem;
}

.mr30 {
  margin-right: 0.3rem;
}

.mr32 {
  margin-right: 0.32rem;
}

.mr40 {
  margin-right: 0.4rem;
}

.mr50 {
  margin-right: 0.5rem;
}

.mr55 {
  margin-right: 0.55rem;
}

.mr70 {
  margin-right: 0.7rem;
}

.mr80 {
  margin-right: 0.8rem;
}

.mr100 {
  margin-right: 1rem;
}

.mr110 {
  margin-right: 1.1rem;
}

.mr120 {
  margin-right: 1.2rem;
}

.ml5 {
  margin-left: 0.05rem;
}

.ml7 {
  margin-left: 0.07rem;
}

.ml70 {
  margin-left: 0.7rem;
}

.ml10 {
  margin-left: 0.1rem;
}

.ml15 {
  margin-left: 0.15rem;
}

.ml20 {
  margin-left: 0.2rem;
}

.ml23 {
  margin-left: 0.23rem;
}

.ml24 {
  margin-left: 0.24rem;
}

.ml25 {
  margin-left: 0.25rem;
}

.ml30 {
  margin-left: 0.3rem;
}

.ml32 {
  margin-left: 0.32rem;
}

.ml34 {
  margin-left: 0.34rem;
}

.ml40 {
  margin-left: 0.4rem;
}

.ml45 {
  margin-left: 0.45rem;
}

.ml45 {
  margin-left: 0.47rem;
}

.ml50 {
  margin-left: 0.5rem;
}

.ml55 {
  margin-left: 0.55rem !important;
}

.ml60 {
  margin-left: 0.6rem;
}

.ml70 {
  margin-left: 0.7rem;
}

.ml80 {
  margin-left: 0.8rem;
}

.ml90 {
  margin-left: 0.9rem;
}

.ml100 {
  margin-left: 1rem;
}

.ml120 {
  margin-left: 1.2rem;
}

.ml140 {
  margin-left: 1.4rem;
}

.ml150 {
  margin-left: 1.5rem;
}

.ml160 {
  margin-left: 1.6rem;
}

.ml222 {
  margin-left: 2.22rem;
}

.ml300 {
  margin-left: 3rem;
}

/*填充*/

.p0 {
  padding: 0 !important;
}

.p5 {
  padding: 0.05rem;
}

.p10 {
  padding: 0.1rem;
}

.p15 {
  padding: 0.15rem;
}

.p20 {
  padding: 0.2rem;
}

.p25 {
  padding: 0.25rem;
}

.p30 {
  padding: 0.3rem;
}

/* 上填充*/

.pt3 {
  padding-top: 0.03rem;
}

.pt5 {
  padding-top: 0.05rem;
}

.pt10 {
  padding-top: 0.1rem;
}

.pt15 {
  padding-top: 0.15rem;
}

.pt20 {
  padding-top: 0.2rem;
}

.pt24 {
  padding-top: 0.24rem;
}

.pt25 {
  padding-top: 0.25rem;
}

.pt30 {
  padding-top: 0.3rem;
}

.pt32 {
  padding-top: 0.32rem;
}

.pt35 {
  padding-top: 0.35rem;
}

.pt40 {
  padding-top: 0.4rem;
}

.pt48 {
  padding-top: 0.48rem;
}

.pt50 {
  padding-top: 0.5rem;
}

.pt60 {
  padding-top: 0.6rem;
}

.pt70 {
  padding-top: 0.7rem;
}

.pt80 {
  padding-top: 0.8rem;
}

.pt90 {
  padding-top: 0.9rem;
}

.pt100 {
  padding-top: 1rem;
}

.pt110 {
  padding-top: 1.1rem;
}

.pt120 {
  padding-top: 1.2rem;
}

/*下填充*/

.pb3 {
  padding-bottom: 0.03rem;
}

.pb5 {
  padding-bottom: 0.05rem;
}

.pb10 {
  padding-bottom: 0.1rem;
}

.pb15 {
  padding-bottom: 0.15rem;
}

.pb18 {
  padding-bottom: 0.18rem;
}

.pb20 {
  padding-bottom: 0.2rem;
}

.pb24 {
  padding-bottom: 0.24rem;
}

.pb25 {
  padding-bottom: 0.25rem;
}

.pb30 {
  padding-bottom: 0.3rem;
}

.pb32 {
  padding-bottom: 0.32rem;
}

.pb35 {
  padding-bottom: 0.35rem;
}

.pb40 {
  padding-bottom: 0.4rem;
}

.pb48 {
  padding-bottom: 0.48rem;
}

.pb45 {
  padding-bottom: 0.45rem;
}

.pb50 {
  padding-bottom: 0.5rem;
}

.pb55 {
  padding-bottom: 0.55rem;
}

.pb60 {
  padding-bottom: 0.6rem;
}

.pb68 {
  padding-bottom: 0.68rem;
}

.pb70 {
  padding-bottom: 0.7rem;
}

.pb80 {
  padding-bottom: 0.8rem;
}

.pb100 {
  padding-bottom: 1rem;
}

.pb120 {
  padding-bottom: 1.2rem;
}

.pb130 {
  padding-bottom: 1.3rem;
}

.pb140 {
  padding-bottom: 1.4rem;
}

.pb150 {
  padding-bottom: 1.5rem;
}

/* 左填充 */

.pl0 {
  padding-left: 0 !important;
}

.pl5 {
  padding-left: 0.05rem;
}

.pl10 {
  padding-left: 0.1rem;
}

.pl15 {
  padding-left: 0.15rem;
}

.pl20 {
  padding-left: 0.2rem;
}

.pl24 {
  padding-left: 0.24rem;
}

.pl25 {
  padding-left: 0.25rem;
}

.pl30 {
  padding-left: 0.3rem;
}

.pl32 {
  padding-left: 0.32rem;
}

.pl35 {
  padding-left: 0.35rem;
}

.pl40 {
  padding-left: 0.4rem;
}

.pl48 {
  padding-left: 0.48rem;
}

.pl45 {
  padding-left: 0.45rem;
}

.pl50 {
  padding-left: 0.5rem;
}

.pl55 {
  padding-left: 0.55rem;
}

.pl60 {
  padding-left: 0.6rem;
}

.pl70 {
  padding-left: 0.7rem;
}

.pl75 {
  padding-left: 0.75rem;
}

.pl80 {
  padding-left: 0.8rem;
}

.pl90 {
  padding-left: 0.9rem;
}

.pl100 {
  padding-left: 1rem;
}

.pl110 {
  padding-left: 1.1rem;
}

.pl120 {
  padding-left: 1.2rem;
}

.pl130 {
  padding-left: 1.3rem;
}

.pl140 {
  padding-left: 1.4rem;
}

.pl150 {
  padding-left: 1.5rem;
}

.pl160 {
  padding-left: 1.6rem;
}

.pl240 {
  padding-left: 2.4rem;
}

.pl250 {
  padding-left: 2.5rem;
}

.pl255 {
  padding-left: 2.55rem;
}

.pl355 {
  padding-left: 3.55rem;
}

.pl260 {
  padding-left: 2.6rem;
}

.pl363 {
  padding-left: 3.63rem;
}

.pl438 {
  padding-left: 4.38rem;
}

/*右填充*/

.pr0 {
  padding-right: 0 !important;
}

.pr5 {
  padding-right: 0.05rem;
}

.pr10 {
  padding-right: 0.1rem;
}

.pr15 {
  padding-right: 0.15rem;
}

.pr20 {
  padding-right: 0.2rem;
}

.pr24 {
  padding-right: 0.24rem;
}

.pr25 {
  padding-right: 0.25rem;
}

.pr30 {
  padding-right: 0.3rem;
}

.pr32 {
  padding-right: 0.32rem;
}

.pr40 {
  padding-right: 0.4rem;
}

.pr48 {
  padding-right: 0.48rem;
}

.pr50 {
  padding-right: 0.5rem;
}

.pr60 {
  padding-right: 0.6rem;
}

.pr70 {
  padding-right: 0.7rem;
}

.pr80 {
  padding-right: 0.8rem;
}

/* 宽度*/

.w_50 {
  width: 0.5rem;
}

.w_55 {
  width: 0.55rem;
}

.w_60 {
  width: 0.6rem;
}

.w_70 {
  width: 0.7rem;
}

.w_72 {
  width: 0.72rem;
}

.w_75 {
  width: 0.75rem;
}

.w_80 {
  width: 0.8rem;
}

.w_85 {
  width: 0.85rem;
}

.w_90 {
  width: 0.9rem;
}

.w_95 {
  width: 0.95rem;
}

.w_100 {
  width: 1rem;
}

.w_105 {
  width: 1.05rem;
}

.w_110 {
  width: 1.1rem;
}

.w_130 {
  width: 1.3rem;
}

.w_120 {
  width: 1.2rem;
}

.w_125 {
  width: 1.25rem;
}

.w_130 {
  width: 1.3rem;
}

.w_140 {
  width: 1.4rem;
}

.w_150 {
  width: 1.5rem;
}

.w_160 {
  width: 1.6rem;
}

.w_170 {
  width: 1.7rem;
}

.w_175 {
  width: 1.75rem;
}

.w_180 {
  width: 1.8rem;
}

.w_200 {
  width: 2rem;
}

.w_220 {
  width: 2.2rem;
}

.w_240 {
  width: 2.4rem;
}

.w_250 {
  width: 2.5rem;
}

.w_260 {
  width: 2.6rem;
}

.w_270 {
  width: 2.7rem;
}

.w_280 {
  width: 2.8rem;
}

.w_300 {
  width: 3rem;
}

.w_305 {
  width: 3.05rem;
}

.w_310 {
  width: 3.1rem;
}

.w_320 {
  width: 3.2rem;
}

.w_340 {
  width: 3.4rem;
}

.w_350 {
  width: 3.5rem;
}

.w_365 {
  width: 3.65rem;
}

.w_370 {
  width: 3.7rem;
}

.w_380 {
  width: 3.8rem;
}

.w_390 {
  width: 3.9rem;
}

.w_400 {
  width: 4rem;
}

.w_420 {
  width: 4.2rem;
}

.w_430 {
  width: 4.3rem;
}

.w_440 {
  width: 4.4rem;
}

.w_450 {
  width: 4.5rem;
}

.w_460 {
  width: 4.6rem;
}

.w_480 {
  width: 4.8rem;
}

.w_500 {
  width: 5rem;
}

.w_540 {
  width: 5.4rem;
}

.w_560 {
  width: 5.6rem;
}

.w_600 {
  width: 6rem;
}

.w_640 {
  width: 6.4rem;
}

.w_820 {
  width: 8.2rem;
}

.w_850 {
  width: 8.5rem;
}

.w_990 {
  width: 9.9rem;
}

.w_950 {
  width: 9.5rem;
}

.w_1000 {
  width: 10rem;
}

.w_1050 {
  width: 10.5rem;
}

.w_1100 {
  width: 11rem;
}

.w_1190 {
  width: 11.9rem;
  margin: 0 auto;
}

.w_1114 {
  width: 11.14rem;
  margin: 0 auto;
}

/*活动模块宽度*/

.p_10 {
  width: 10%;
}

.p_30 {
  width: 30%;
}

.p_33 {
  width: 33.333333333333333333%;
}

.p_35 {
  width: 35%;
}

.p_40 {
  width: 40%;
}

.p_45 {
  width: 45%;
}

.p_48 {
  width: 48%;
}

.p_49 {
  width: 49%;
}

.p_50 {
  width: 50%;
}

.p_55 {
  width: 55%;
}

.p_60 {
  width: 60%;
}

.p_66 {
  width: 66.666666666666666666%;
}

.p_70 {
  width: 70%;
}

.p_80 {
  width: 80%;
}

.p_90 {
  width: 90%;
}

.p_95 {
  width: 95%;
}

.p_100 {
  width: 100% !important;
}

.calc_50 {
  width: calc(100% - 0.5rem);
}

.calc_70 {
  width: calc(100% - 0.7rem);
}

.calc_80 {
  width: calc(100% - 0.8rem);
}

.calc_100 {
  width: calc(100% - 1rem);
}

.calc_110 {
  width: calc(100% - 1.1rem);
}

.calc_220 {
  width: calc(100% - 2.2rem);
}

/* 高度 && 行高 */

.lht16 {
  line-height: 0.16rem;
}

.lht24 {
  line-height: 0.24rem;
}

.lht27 {
  line-height: 0.27rem;
}

.lht28 {
  line-height: 0.28rem;
}

.lht32 {
  line-height: 0.32rem;
}

.lht34 {
  line-height: 0.34rem;
}

.lht36 {
  line-height: 0.36rem;
}

.lht40 {
  line-height: 0.4rem;
}

.lht44 {
  line-height: 0.44rem;
}

.lht48 {
  line-height: 0.48rem;
}

.lht64 {
  line-height: 0.64rem;
}

.lht72 {
  line-height: 0.72rem;
}

.lh20 {
  height: 0.2rem;
  line-height: 0.2rem;
}

.lh24 {
  height: 0.24rem;
  line-height: 0.24rem;
}

.lh25 {
  height: 0.25rem;
  line-height: 0.25rem;
}

.lh28 {
  height: 0.28rem;
  line-height: 0.28rem;
}

.lh30 {
  height: 0.3rem;
  line-height: 0.3rem;
}

.lh32 {
  height: 0.32rem;
  line-height: 0.32rem;
}

.lh35 {
  height: 0.35rem;
  line-height: 0.35rem;
}

.lh36 {
  height: 0.36rem;
  line-height: 0.36rem;
}

.lh40 {
  height: 0.4rem;
  line-height: 0.4rem;
}

.lh44 {
  height: 0.44rem;
  line-height: 0.44rem;
}

.lh45 {
  height: 0.45rem;
  line-height: 0.45rem;
}

.lh48 {
  height: 0.48rem;
  line-height: 0.48rem;
}

.lh50 {
  height: 0.5rem;
  line-height: 0.5rem;
}

.lh60 {
  height: 0.6rem;
  line-height: 0.6rem;
}

.lh64 {
  height: 0.65rem;
  line-height: 0.65rem;
}

.lh70 {
  height: 0.7rem;
  line-height: 0.7rem;
}

.lh80 {
  height: 0.8rem;
  line-height: 0.8rem;
}

.lh90 {
  height: 0.9rem;
  line-height: 0.9rem;
}

.lh100 {
  height: 1rem;
  line-height: 1rem;
}

.lh120 {
  height: 1.2rem;
  line-height: 1.2rem;
}

.lh135 {
  height: 1.35rem;
  line-height: 1.35rem;
}

.lh154 {
  height: 1.54rem;
  line-height: 1.54rem;
}

.lh_150 {
  line-height: 150%;
}

.lh_180 {
  line-height: 180%;
}

.lh_200 {
  line-height: 200%;
}

.h_100 {
  height: 100%;
}

/*背景颜色*/

.bg_cf5 {
  background-color: #f5f5f5;
}

.bg_cf7 {
  background-color: #f7f7f7;
}

.bg_cf3 {
  background-color: #f3f3f3;
}

.bg_fff {
  background-color: #ffffff;
}

.bg_222 {
  background-color: #222222;
}

.bg_stand {
  background-color: #f8fafa;
}

.bg_green {
  background: #99ce58;
}

.bg_blue {
  background: #45d1ff;
}

/*字体颜色*/

.color_white {
  color: #fff !important;
}

.color_black {
  color: #000 !important;
}

.color_red {
  color: #ef5d57 !important;
}

.color_gray {
  color: rgba(0,0,0,0.4);
}

.color_4242 {
  color: #424242;
}

.color_4a4a {
  color: #4a4a4a;
}

.color_3d3d {
  color: #3d3d3d;
}

.color_7d7d {
  color: #7d7d7d;
}

.color_9b9b {
  color: #9b9b9b;
}

.color_222 {
  color: #222;
}

.color_333 {
  color: #333;
}

.color_666 {
  color: #666;
}

.color_999 {
  color: #999;
}

.color_main {
  color: #7F4E23;
}

.color_wine {
  color: #6A1930;
}

.color_crimson {
  color: #D0021B;
}

.color_scarlet {
  color: #E02020;
}

.color_theme {
  color: #FE0000;
}

.color_05cf {
  color: #005CFF;
}

/*过渡*/

.transition {
  transition: all .2s ease-out;
}

/*隐藏显示*/

.hidden {
  visibility: hidden;
  opacity: 0;
}

.hide {
  display: none !important;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.flex_center {
  display: flex;
  justify-content: center;
}

.flex_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex_around {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.flex_absolute_center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex_column_center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.inline_block {
  display: inline-block;
}

.box_shadow {
  box-shadow: 0 0 0.2rem 0.01rem rgba(0,0,0,0.2);
}

.border_box {
  box-sizing: border-box;
}

/*边框*/

.border {
  border: 0.01rem solid #EEEDF3;
}

.border_top {
  border-top: 0.01rem solid #EEEDF3;
}

.border_bottom {
  border-bottom: 0.01rem solid #EEEDF3;
}

/*圆角*/

.radius5 {
  border-radius: 0.05rem;
}

.radius8 {
  border-radius: 0.08rem;
}

.radius10 {
  border-radius: 0.1rem;
}

.radius20 {
  border-radius: 0.2rem;
}

.radius30 {
  border-radius: 0.3rem !important;
}

.radius40 {
  border-radius: 0.4rem !important;
}

/* 省略 */

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.line_clamp2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/* 高度 */

.h10 {
  height: 0.1rem;
}

.h15 {
  height: 0.15rem;
}

.h20 {
  height: 0.2rem;
}

.h25 {
  height: 0.25rem;
}

.h30 {
  height: 0.3rem;
}

.h50 {
  height: 0.5rem;
}

.h80 {
  height: 0.8rem;
}

.h188 {
  height: 1.88rem;
}

.h200 {
  height: 2rem;
}

.tar {
  text-align: right;
}

.page_mobile {
  width: 100vw;
  height: auto;
  background-color: #F7F7F7;
  font-family: PingFang SC, PingFang SC;
  overflow-y: auto;
  padding-top: 1.5rem;
}