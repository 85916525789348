.float-toast-box {
  position: fixed;
  right: 0;
  top: 15%;
  z-index: 1000;
}

.pre_sales {
  width: 0.6rem;
  height: 1.81rem;
  background: #E43535;
  box-shadow: 0 0.06rem 0.4rem 0 rgba(0,0,0,0.1);
  border-radius: 0.3rem;
  font-size: 0.18rem;
  cursor: pointer;
}

.pre_sale_icon {
  width: 0.44rem;
  height: 0.44rem;
}

.pre_sale_qr_code {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pre_sale_qr_code_img {
  width: 100%;
  height: auto;
}

.consult_wrap {
  width: 0.6rem;
  height: 2.37rem;
  background: #FFFFFF;
  box-shadow: 0 0.06rem 0.4rem 0 rgba(0,0,0,0.1);
  border-radius: 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}

.consult_item {
  width: 0.5rem;
  height: 0.7rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.12rem;
  color: #000;
  cursor: pointer;
}

.consult_item_icon {
  width: 0.26rem;
  height: 0.26rem;
}

.telephone_consult {
  width: 100%;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.1rem 0;
}

.telephone_consult_header {
  width: 100%;
  display: flex;
  align-items: center;
}

.telephone_consult_header_icon {
  width: 0.46rem;
  height: 0.46rem;
}

.telephone_consult_header_content {
  height: 0.46rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.telephone_consult_header_content_title {
  font-size: 0.15rem;
  color: #000000;
  font-weight: 400;
}

.telephone_consult_header_content_number {
  font-size: 0.18rem;
  color: #3775FF;
  font-weight: 600;
  letter-spacing: 0.02rem;
}

.telephone_consult_input {
  height: 0.42rem;
  font-size: 0.16rem;
  margin-top: 0.14rem;
}

.telephone_consult_desc {
  font-weight: 400;
  font-size: 0.13rem;
  color: rgba(0,0,0,0.6);
}

.telephone_consult_button {
  width: 100%;
  height: 0.41rem;
  background: linear-gradient( 90deg, #4588FF 0%, #206EF7 100%);
  border-radius: 0.04rem;
  line-height: 0.41rem;
  font-size: 0.15rem;
  color: #FFFFFF;
  cursor: pointer;
}

.telephone_consult_success {
  width: 0.62rem;
  height: 0.62rem;
}

.telephone_consult_success_title {
  font-size: 0.14rem;
  color: #000000;
}

.telephone_consult_success_tip {
  font-size: 0.12rem;
  color: #000000;
}

.telephone_consult_success_desc {
  font-size: 0.12rem;
  color: rgba(0,0,0,0.6);
  margin-top: 0.04rem;
}