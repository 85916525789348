.safe_wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.safe_header {
  width: 100%;
  height: 5.95rem;
  background: #0064FF;
}

.header_left {
  width: 5rem;
}

.header_right {
  width: 9rem;
  height: 5.95rem;
}

.safe_header_btn {
  width: 2.66rem;
  height: 0.74rem;
  line-height: 0.74rem;
  background: #FFFFFF;
  box-shadow: 0.02rem -0.02rem 0.14rem 0 rgba(81,106,152,0.24);
  border-radius: 0.18rem;
  cursor: pointer;
}

.safe_tabs {
  width: 14.78rem;
}

.safe_tab_item {
  width: 3.1rem;
  height: 2.56rem;
}

.safe_tab_item_icon {
  width: 1.48rem;
  height: 1.48rem;
}

.safe_tab_item_label {
  color: rgba(0, 0, 0, 0.9);
}

.safe_tab_item_desc {
  color: rgba(0, 0, 0, 0.75);
}

.safe_module_item {
  background: #FFFFFF;
}

.safe_module_item_card {
  width: 13.74rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.safe_module_item_title {
  display: flex;
  align-items: center;
}

.safe_module_item_desc {
  width: 5rem;
  color: rgba(0, 0, 0, 0.8);
}

.safe_module_item_right_image {
  width: 7.23rem;
  height: 5.6rem;
}