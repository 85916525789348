.mobile_partner_content_img {
  width: 100vw;
  height: auto;
}

.mobile_partner_content_title {
  font-size: 0.6rem;
  margin-left: 0.6rem;
  margin-top: 1rem;
}

.mobile_partner_content_desc {
  margin-bottom: 0.6rem;
  margin-top: 0.2rem;
  font-size: 0.35rem;
  font-weight: 400;
  margin-left: 0.6rem;
  color: rgba(0, 0, 0, 0.75);
}