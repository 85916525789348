.mobile_service_wrap {
  padding: 0.3rem;
}

.mobile_service_title {
  font-size: 0.5rem;
  font-weight: 600;
  margin-top: 0.6rem;
}

.mobile_service_status_wrap {
  margin-top: 0.6rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.mobile_service_status_item {
  width: 2.8rem;
  height: 2.8rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url("../../../../static/images//product/system_state_bg.png") no-repeat;
  background-size: 100%;
  margin: 0.4rem 0.2rem;
}

.mobile_service_status_item_icon {
  width: 1rem;
  height: 1rem;
  margin-top: 0.9rem;
}

.mobile_service_status_item_title {
  margin-top: 0.2rem;
  font-size: 0.33rem;
}

.mobile_service_status_item_desc {
  width: 3.1rem;
  margin-top: 0.2rem;
  text-align: center;
}

.mobile_service_status_month_warp {
  width: 100%;
  background: rgba(255,255,255,0.2);
  border-radius: 0.2rem;
  border: 0.02rem solid #FFFFFF;
  margin-top: 1rem;
  padding: 0.6rem 0;
}

.mobile_service_status_month_title {
  font-size: 0.45rem;
  font-weight: 600;
}

.mobile_state_collect_card_item {
  width: 90vw;
  margin-top: 0.3rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.ant-popover-content {
  width: 2.5rem;
  font-size: 0.4rem;
  border-radius: 0.1rem;
}

.state_dote {
  width: 0.3rem;
  height: 0.3rem;
  background: #00DF1D;
  border-radius: 0.15rem;
  margin: 0 auto;
  margin-top: 0.3rem;
}

.state_dote_abnormality {
  background: #FF8000;
}

.mobile_international_certification {
  margin-top: 1rem;
}

.mobile_international_certification_title {
  font-size: 0.45rem;
  font-weight: 600;
}

.mobile_international_certification_card {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.mobile_international_certification_item {
  width: 40vw;
  height: 4rem;
  background: #FFFFFF;
  box-shadow: 0rem 0.03rem 0.2rem 0rem rgba(0,20,55,0.15);
  border-radius: 0.12rem;
  margin-top: 0.7rem;
}

.mobile_international_certification_item_icon {
  width: 2rem;
  height: 2rem;
  margin-top: 0.4rem;
}

.mobile_international_certification_item_title {
  font-size: 0.4rem;
  font-weight: 600;
}

.mobile_international_certification_item_desc {
  font-size: 0.34rem;
  color: #323232;
  margin-top: 0.2rem;
}