.download {
  height: 3.37rem;
  width: 100%;
  padding: 0.7rem 2.17rem 0.89rem 1.5rem;
}

.download .header .left {
  font-weight: 400;
  font-size: 0.26rem;
  color: #000000;
  line-height: 0.36rem;
  margin-right: 0.22rem;
}

.download .header .right {
  font-weight: 400;
  font-size: 0.14rem;
  color: rgba(0, 0, 0, 0.8);
  line-height: 0.36rem;
}

.download .download-con {
  align-items: center;
}

.download .download-con .download-con-left .download-con-left-img {
  width: 0.8rem;
  height: 1.1rem;
  margin-right: 0.05rem;
}

.download .download-con .download-con-left .name {
  height: 0.42rem;
  font-weight: 600;
  font-size: 0.3rem;
  color: rgba(0, 0, 0, 0.78);
  line-height: 0.42rem;
  margin-top: 0.14rem;
  margin-bottom: 0.15rem;
}

.download .download-con .download-con-left .tip {
  height: 0.25rem;
  font-weight: 400;
  font-size: 0.18rem;
  color: rgba(0, 0, 0, 0.78);
  line-height: 0.25rem;
}

.download .download-con .download-con-right {
  width: 1.72rem;
  height: 0.64rem;
  background: linear-gradient(90deg, #4588FF 0%, #206EF7 100%);
  box-shadow: 0.02rem -0.02rem 0.14rem 0 rgba(81, 106, 152, 0.24);
  border-radius: 0.32rem;
  font-weight: 500;
  font-size: 0.24rem;
  color: #FFFFFF;
  line-height: 0.64rem;
  text-align: center;
  cursor: pointer;
}