.about-me .about-me-introduce {
  height: 8.77rem;
  background: linear-gradient(270deg, rgba(226, 236, 255, 0.8) 0%, rgba(255, 255, 255, 0) 100%);
  padding: 1rem 2.56rem 0.52rem 2.6rem;
}

.about-me .about-me-introduce .img1 {
  margin-right: 0.38rem;
}

.about-me .about-me-introduce .right .img2 {
  width: 5.39rem;
  height: 1.21rem;
  margin-bottom: 0.74rem;
}

.about-me .about-me-introduce .right .item .item-img {
  width: 0.42rem;
  height: 0.42rem;
}

.about-me .about-me-introduce .right .item .item-title {
  font-weight: normal;
  font-size: 0.2rem;
  color: #000000;
  line-height: 0.42rem;
}

.about_me_banner_warp {
  background: rgba(207,222,255,0.2);
}

.about_me_banner_title {
  letter-spacing: 0.02rem;
}

.about_me_banner_desc {
  color: rgba(0, 0, 0, 0.6);
}

.about_me_banner_card {
  width: 12.3rem;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 0.7rem;
  margin-bottom: 0.8rem;
}

.about_me_banner_card_item_img {
  width: 5.85rem;
  height: 4.6rem;
  margin: 0.15rem;
}

.about_me_timeline_line_dote_item {
  height: 0.32rem;
  cursor: pointer;
  position: relative;
}

.about_me_timeline_line_dote_active {
  width: 0.52rem;
  height: 0.52rem;
  border-radius: 0.26rem;
  background: #DDEBFF;
  border: 0.01rem solid rgba(50,123,251,0.5);
  z-index: -1;
  position: absolute;
  left: -0.1rem;
  top: -0.1rem;
}

.about_me_timeline_line_dote {
  width: 0.32rem;
  height: 0.32rem;
  background: #FFFFFF;
  border: 0.06rem solid #FFFFFF;
  border-radius: 0.16rem;
}

.about_me_timeline_line_dote_bg {
  width: 0.18rem;
  height: 0.18rem;
  background: #5A96FF;
  border-radius: 0.09rem;
}

.about_me_timeline_line_dote_year {
  width: max-content;
  position: absolute;
  bottom: -0.78rem;
}

.about-me .about-me-ability {
  height: 9.39rem;
  background: rgba(207, 222, 255, 0.2);
  text-align: center;
}

.about-me .about-me-ability .title {
  height: 0.7rem;
  font-weight: normal;
  font-size: 0.5rem;
  color: #000000;
  line-height: 0.7rem;
  letter-spacing: 0.02rem;
}

.about-me .about-me-ability .illustrate {
  height: 0.49rem;
  font-weight: normal;
  font-size: 0.3rem;
  color: rgba(0, 0, 0, 0.6);
  line-height: 0.42rem;
  letter-spacing: 0.01rem;
}

.about-me .about-me-ability .introduce {
  padding: 0 1.56rem;
  height: 4.1rem;
}

.about-me .about-me-ability .introduce .left {
  width: 0.6rem;
  height: 0.6rem;
  background: rgba(0, 0, 0, 0.16);
  border-radius: 50%;
  margin: auto 0;
  cursor: pointer;
}

.about-me .about-me-ability .introduce .center {
  width: 13.16rem;
  background: #FFFFFF;
  box-shadow: 0 0.06rem 0.2rem 0 rgba(0, 55, 150, 0.15);
  border-radius: 0.1rem;
}

.about-me .about-me-ability .introduce .center .center-left {
  width: 6.18rem;
  height: 4.1rem;
  box-shadow: 0 0.06rem 0.2rem 0 rgba(0, 55, 150, 0.15);
  border-radius: 0.1rem;
  border: 0.1rem solid #FFFFFF;
  margin-right: 0.2rem;
}

.about-me .about-me-ability .introduce .center .center-right {
  text-align: left;
}

.about-me .about-me-ability .introduce .center .center-right .center-right-title {
  height: 0.48rem;
  font-weight: normal;
  font-size: 0.34rem;
  color: #4588FF;
  line-height: 0.48rem;
  letter-spacing: 0.02rem;
}

.about-me .about-me-ability .introduce .center .center-right .center-right-con {
  width: 6.18rem;
  height: 1.92rem;
  font-weight: normal;
  font-size: 0.3rem;
  color: #3D3D3D;
  line-height: 0.48rem;
  letter-spacing: 0.02rem;
}

.about-me .about-me-ability .time-line {
  height: 0.04rem;
  background: rgba(107, 161, 255, 0.1);
  margin-top: 1.24rem;
  padding: 0 5.7rem;
}

.about-me .about-me-ability .time-line .item {
  width: 0.32rem;
  height: 0.32rem;
  background: #5A96FF;
  border: 0.06rem solid #FFFFFF;
  border-radius: 50%;
  margin-top: -0.14rem;
}

.about-me .about-me-ability .years {
  padding: 0 5.3rem;
}

.about-me .about-me-ability .years .year {
  font-weight: normal;
  font-size: 0.34rem;
  color: #3D3D3D;
  line-height: 0.48rem;
  letter-spacing: 0.02rem;
}

.about-me .about-me-illustrate {
  height: 4rem;
  width: 100%;
  padding: 1rem 2.38rem 1rem 2.86rem;
  text-align: center;
}

.about-me .about-me-illustrate .one {
  font-weight: 400;
  font-size: 0.3rem;
  color: rgba(0, 0, 0, 0.9);
  line-height: 0.48rem;
}

.about-me .about-me-illustrate .two {
  font-weight: 600;
}

.about-me .about-me-flow {
  width: 100%;
  height: 25.56rem;
  background: url('../../static/images/about/about_us_content_bg.png') no-repeat;
  background-size: 100%;
  background-position: center center;
}