.none {
  display: none;
}

.block {
  display: block;
}

.page {
  background-color: #fff;
  /* height: 400px; */
}

.banner {
  height: 9.74rem;
  background: url('../home/home_header_banner_bg.png') no-repeat;
  background-size: 100%;
}

.banner .title {
  position: relative;
}

.banner .title span {
  position: absolute;
  right: -1.2rem;
  bottom: 0.05rem;
  font-size: 0.2rem;
}

.home_download_btn {
  width: 2.32rem;
  height: 0.76rem;
  line-height: 0.76rem;
  background: #FFFFFF;
  box-shadow: 0.02rem -0.02rem 0.14rem 0 rgba(81,106,152,0.24);
  border-radius: 0.43rem 0.43rem 0.43rem 0.43rem;
  cursor: pointer;
}

.home_banner_tabs {
  width: 100%;
  height: 0.99rem;
  background: linear-gradient( 90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 54%, rgba(255,255,255,0) 100%);
}

.home_banner_tabs_item {
  height: 0.99rem;
  padding: 0 0.3rem;
  cursor: pointer;
  letter-spacing: 0.02rem;
  margin: 0 0.2rem;
}

.home_banner_tabs_item_icon {
  width: 0.44rem;
  height: 0.44rem;
}

.home_banner_tabs_item_icon image {
  width: 0.22rem;
  height: 0.22rem;
  background-color: pink;
}

.home_banner_tabs_item_active {
  background: rgba(255,255,255,0.8);
  border-radius: 0.3rem;
  color: #333333;
}

.home_banner_tabs_item_img {
  width: 12.77rem;
  height: 4.53rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home_banner_tabs_item_img img {
  width: auto;
  height: 4.35rem;
}

.offer_plan_wrap {
  height: 9.9rem;
  background: rgba(247,247,247,0.8);
}

.plan_top {
  width: 17.44rem;
  background: #FFFFFF;
  box-shadow: 0 0.06rem 0.2rem 0 rgba(0,0,0,0.03);
  display: flex;
  flex-direction: column;
  border-radius: 0.18rem;
  padding: 0.2rem 0.44rem;
  z-index: 1;
}

.plan_top_bottom {
  width: 0.56rem;
  height: 0.56rem;
  transform: rotate(45deg);
  background: #FFFFFF;
  box-shadow: 0 0.06rem 0.2rem 0 rgba(0,0,0,0.03);
  margin: 0 auto;
  margin-top: -0.28rem;
  z-index: 0;
}

.plan_top_content_item {
  width: 2.4rem;
  justify-content: center;
}

.item_icon {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 1.2rem;
}

.item_title {
  margin: 0.2rem 0;
}

.item_desc {
  width: 2rem;
  height: 0.7rem;
  line-height: 0.35rem;
}

.plan_steps {
  width: 17.44rem;
  height: 1.76rem;
  background: #FFFFFF;
  box-shadow: 0 0.06rem 0.2rem 0 rgba(0,0,0,0.03);
  border-radius: 0.88rem;
}

.plus_icon {
  margin: 0 0.8rem;
}

.major_and_dominance {
  height: 4.15rem;
  background: url("../home/major_and_dominance_bg.png") no-repeat;
  background-size: 100%;
}

.major_and_dominance_info_item {
  margin: 0 0.35rem;
}

.kernel_business {
  height: 9.21rem;
  background: rgba(247,247,247,0.8);
  margin: 0.2rem 0;
}

.kernel_business_title {
  width: 16.2rem;
  margin-top: 0.53rem;
}

.business_item {
  width: 4.08rem;
  height: 7.1rem;
  background: #FFFFFF;
  margin: 0 0.01rem;
}

.business_item_active {
  background: url("../home/kernel_business_active_bg.png") no-repeat;
  background-size: 100%;
  border-radius: 0.16rem;
}

.business_item_btn {
  width: 1.52rem;
  height: 0.56rem;
  line-height: 0.56rem;
  box-shadow: 0.02rem -0.02rem 0.14rem 0 rgba(81,106,152,0.24);
  border-radius: 0.08rem;
  cursor: pointer;
}

.business_item_remark {
  width: 3.31rem;
  position: relative;
}

.quotation_mark_top,
.quotation_mark_bottom {
  position: absolute;
}

.quotation_mark_top {
  top: -0.2rem;
  left: 0;
}

.quotation_mark_bottom {
  right: 0;
  bottom: -0.2rem;
}

.business_item_img_border {
  width: 3.51rem;
  height: 4.1rem;
  border-radius: 0.18rem;
}

.business_item_img_border_active {
  border: 0.01rem solid rgba(255,255,255,0.6);
}

.business_item_img {
  width: 3.27rem;
  height: 3.86rem;
  border-radius: 0.18rem;
}

.common_basic_module {
  height: 8.5rem;
  background: rgba(247,247,247,0.8);
}

.common_basic_module_title {
  width: 14.95rem;
}

.common_basic_module_desc {
  width: 14.95rem;
}

.common_basic_module_more {
  height: 0.28rem;
  color: rgba(0,87,255,0.898);
  line-height: 0.28rem;
  cursor: pointer;
}

.basic_modules {
  width: 14.95rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.basic_module {
  width: 2.2rem;
  height: 1.48rem;
  background: linear-gradient( 270deg, #FFFFFF 0%, rgba(255,255,255,0.5) 100%);
  box-shadow: 0 0.02rem 0.13rem 0 rgba(255,166,50,0.07);
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 0.4rem;
  margin-bottom: 0.3rem;
  border-radius: 0.18rem;
}

.basic_module_img {
  width: 0.43rem;
  height: 0.43rem;
}

.customer_evaluates {
  height: 7.45rem;
  background: url("../home/customer_evaluates_bg.png") no-repeat;
  background-size: 100%;
}

.customer_evaluates_content {
  width: 13.28rem;
  height: 4.82rem;
  border-radius: 0.18rem;
  background: rgba(255,255,255,0.2);
  padding: 0.2rem;
  border: 0.01rem solid #FFFFFF;
  position: relative;
}

.carousel_switch_left {
  width: 0.6rem;
  height: 0.6rem;
  background: rgba(0,0,0,0.16);
  border-radius: 0.3rem;
  position: absolute;
  top: 1.74rem;
  left: -1.4rem;
  cursor: pointer;
}

.carousel_switch_right {
  width: 0.6rem;
  height: 0.6rem;
  background: rgba(0,0,0,0.16);
  border-radius: 0.3rem;
  position: absolute;
  top: 1.74rem;
  right: -1.4rem;
  cursor: pointer;
}

.carousel_item {
  width: 12.84rem;
  height: 4.38rem;
  border-radius: 0.18rem;
  background: #ffffff;
  display: flex;
  align-items: center;
  position: relative;
}

.carousel_dots {
  position: absolute;
  bottom: 1rem;
}

.ant-carousel .slick-dots {
  margin-top: 1rem;
}

.ant-carousel .slick-dots li.slick-active button {
  background: #145EF0;
  opacity: 1;
}

.ant-carousel .slick-dots li button {
  background: #AFC7F6;
}

.ant-carousel .slick-dots-bottom {
  bottom: -0.8rem;
}

.carousel_item_left {
  width: 4.41rem;
  height: 3.62rem;
}

.carousel_item_left_img {
  width: 4.41rem;
  height: 3.62rem;
  border-radius: 0.18rem 0.18rem 0 0;
}

.carousel_item_left_img img {
  width: 4.41rem;
  height: 3.62rem;
}

.carousel_item_left_name {
  width: 4.41rem;
  height: 0.7rem;
  line-height: 0.7rem;
  padding-left: 0.48rem;
  border-radius: 0 0 0.18rem 0.18rem;
  background: linear-gradient( 270deg, #CEDAEF 0%, rgba(206,218,239,0.31) 100%);
}

.carousel_item_right {
  width: 7.13rem;
  height: 3.62rem;
  margin-left: 0.47rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.carousel_item_right_top,
.carousel_item_right_bottom {
  display: flex;
  align-items: baseline;
  flex-shrink: 1;
}

.carousel_item_right_dot {
  width: 0.22rem;
  height: 0.22rem;
  background: #98ADFF;
  border-radius: 0.11rem;
  margin-right: 0.21rem;
}

.carousel_item_right_bottom_content {
  width: 6.7rem;
  text-align: justify;
}

.user_common_choice {
  background-color: #ffffff;
}

.common_choice_title {
  margin-left: 2.2rem;
}

.common_choice_desc {
  align-items: center;
  justify-content: flex-start;
  margin-top: 0.42rem;
}

.common_choice_desc_item_title {
  color: #0066D4;
}

.common_choice_desc_item {
  align-items: baseline;
}

.user_common_choice_img_wrap {
  width: 100%;
  height: 8.06rem;
  background: url('../home/user_common_choice_img_bg.png') no-repeat;
  background-size: 100%;
  position: relative;
}

.common_choice_img {
  background: #4885FF;
  font-weight: bold;
  color: #FFFFFF;
  position: absolute;
}

.user_common_choice_img1 {
  width: 4.73rem;
  height: 2.09rem;
  top: 1.19rem;
  left: 3.9rem;
  background: url('../home/common_choice_img_bg1.png') no-repeat;
  background-size: 4.73rem 2.09rem;
  background-position: 0 0;
}

.user_common_choice_img2 {
  width: 4.59rem;
  height: 2.57rem;
  top: 1.06rem;
  left: 9.67rem;
  background: url('../home/common_choice_img_bg2.png') no-repeat;
  background-size: 4.59rem 2.57rem;
  background-position: 0 0;
}

.user_common_choice_img3 {
  width: 4.11rem;
  height: 1.28rem;
  top: 1.05rem;
  left: 12.64rem;
  background: url('../home/common_choice_img_bg3.png') no-repeat;
  background-size: 4.11rem 1.28rem;
  background-position: 0 0;
}

.user_common_choice_img4 {
  width: 5.34rem;
  height: 0.98rem;
  top: 3.19rem;
  left: 2.03rem;
  background: url('../home/common_choice_img_bg4.png') no-repeat;
  background-size: 5.34rem 0.98rem;
  background-position: 0 0;
}

.user_common_choice_img5 {
  width: 3.8rem;
  height: 1.06rem;
  top: 2.77rem;
  left: 5.6rem;
  background: url('../home/common_choice_img_bg5.png') no-repeat;
  background-size: 3.8rem 1.06rem;
  background-position: 0 0;
}

.user_common_choice_img6 {
  width: 4.14rem;
  height: 0.9rem;
  top: 3.36rem;
  left: 9.79rem;
  line-height: 0.45rem;
  background: url('../home/common_choice_img_bg6.png') no-repeat;
  background-size: 4.14rem 0.9rem;
  background-position: 0 0;
}

.user_common_choice_img7 {
  width: 6.36rem;
  height: 1.06rem;
  top: 3.09rem;
  left: 11.29rem;
  background: url('../home/common_choice_img_bg7.png') no-repeat;
  background-size: 6.36rem 1.06rem;
  background-position: 0 0;
}

.user_common_choice_img8 {
  width: 4.51rem;
  height: 1.71rem;
  top: 4.04rem;
  left: 4.54rem;
  background: url('../home/common_choice_img_bg8.png') no-repeat;
  background-size: 4.51rem 1.71rem;
  background-position: 0 0;
}

.user_common_choice_img9 {
  width: 3.15rem;
  height: 2.93rem;
  top: 3.65rem;
  left: 8.03rem;
  background: url('../home/common_choice_img_bg9.png') no-repeat;
  background-size: 3.15rem 2.93rem;
  background-position: 0 0;
}

.user_common_choice_img10 {
  width: 5.06rem;
  height: 1.72rem;
  top: 4.01rem;
  left: 11rem;
  background: url('../home/common_choice_img_bg10.png') no-repeat;
  background-size: 5.06rem 1.72rem;
  background-position: 0 0;
}

.imgAnimation1 {
  animation: move1 0.8s ease-in;
}

.imgAnimation2 {
  animation: move2 0.8s ease-in;
}

.imgAnimation3 {
  animation: move3 0.8s ease-in;
}

.imgAnimation4 {
  animation: move4 0.8s ease-in;
}

.imgAnimation5 {
  animation: move5 0.8s ease-in;
}

.imgAnimation6 {
  animation: move6 0.8s ease-in;
}

.imgAnimation7 {
  animation: move7 0.8s ease-in;
}

.imgAnimation8 {
  animation: move8 0.8s ease-in;
}

.imgAnimation9 {
  animation: move9 0.8s ease-in;
}

.imgAnimation10 {
  animation: move10 0.8s ease-in;
}

@keyframes move1 {
  from {
    top: 3.66rem;
    left: 8rem;
    transform: scale(0);
  }

  to {
    top: 1.19rem;
    left: 3.7rem;
    transform: scale(1);
  }
}

@keyframes move2 {
  from {
    top: 3.66rem;
    left: 8rem;
    transform: scale(0);
  }

  to {
    top: 1.06rem;
    left: 9.67rem;
    transform: scale(1);
  }
}

@keyframes move3 {
  from {
    top: 3.66rem;
    left: 8rem;
    transform: scale(0);
  }

  to {
    top: 1.05rem;
    left: 12.64rem;
    transform: scale(1);
  }
}

@keyframes move4 {
  from {
    top: 3.66rem;
    left: 8rem;
    transform: scale(0);
  }

  to {
    top: 3.19rem;
    left: 1.53rem;
    transform: scale(1);
  }
}

@keyframes move5 {
  from {
    top: 3.66rem;
    left: 8rem;
    transform: scale(0);
  }

  to {
    top: 2.77rem;
    left: 5.6rem;
    transform: scale(1);
  }
}

@keyframes move6 {
  from {
    top: 3.66rem;
    left: 8rem;
    transform: scale(0);
  }

  to {
    top: 3.36rem;
    left: 9.79rem;
    transform: scale(1);
  }
}

@keyframes move7 {
  from {
    top: 3.66rem;
    left: 8rem;
    transform: scale(0);
  }

  to {
    top: 3.09rem;
    left: 11.29rem;
    transform: scale(1);
  }
}

@keyframes move8 {
  from {
    top: 3.66rem;
    left: 8rem;
    transform: scale(0);
  }

  to {
    top: 4.04rem;
    left: 4.54rem;
    transform: scale(1);
  }
}

@keyframes move9 {
  from {
    top: 3.66rem;
    left: 8rem;
    transform: scale(0);
  }

  to {
    top: 3.65rem;
    left: 8.03rem;
    transform: scale(1);
  }
}

@keyframes move10 {
  from {
    top: 3.66rem;
    left: 8rem;
    transform: scale(0);
  }

  to {
    top: 4.01rem;
    left: 11rem;
    transform: scale(1);
  }
}

.advertising_wrap {
  height: 1.54rem;
  background: url("../home/advertising_bg.png") no-repeat;
  background-size: 100%;
}

.advertising_btn {
  width: 2.32rem;
  height: 0.76rem;
  color: #005CFF;
  line-height: 0.76rem;
  background: #FFFFFF;
  box-shadow: 0.02rem -0.02rem 0.14rem 0 rgba(81,106,152,0.24);
  border-radius: 0.43rem;
  float: right;
  margin-right: 1.5rem;
  margin-top: 0.4rem;
  cursor: pointer;
}

.kernel_advantage_desc {
  width: 9.24rem;
  color: #030C22;
}

.kernel_advantage_content {
  height: 6.4rem;
  align-items: center;
  justify-content: center;
}

.kernel_advantage_item {
  width: 4.09rem;
  height: 5rem;
  margin: 0 0.4rem;
  flex-direction: column;
  border-radius: 0.2rem;
  background: url('../home/kernel_advantage_item_bg.png') no-repeat;
  background-size: 4.09rem 5rem;
  position: relative;
}

.kernel_advantage_item_active {
  width: 4.09rem;
  height: 5.63rem;
  background: url('../home/kernel_advantage_item_bg_active.png') no-repeat;
  background-size: 5.09rem 7.2rem;
  background-position: center 0;
}

.kernel_advantage_item_header {
  width: 4.09rem;
  height: 0.6rem;
  border-top: 0.02rem solid;
  position: relative;
}

.kernel_advantage_item_header_active {
  width: 4.09rem;
  height: 0.75rem;
}

.kernel_advantage_item_header_index {
  position: absolute;
  right: 0.32rem;
  top: 0.08rem;
}

.kernel_advantage_item_content {
  width: 4.09rem;
  height: 4.4rem;
}

.kernel_advantage_item_content_active {
  width: 4.09rem;
  height: 4.88rem;
}

.kernel_advantage_item_content_title {
  margin-left: 0.6rem;
  margin-top: 0.66rem;
  position: relative;
}

.kernel_advantage_item_content_title_icon {
  width: 0.3rem;
  height: 0.3rem;
  background: url('../home/kernel_advantage_item_content_title_icon.png') no-repeat;
  background-size: 100%;
  position: absolute;
  left: -0.28rem;
  top: -0.2rem;
}

.kernel_advantage_item_content_list {
  display: flex;
  flex-direction: column;
}

.kernel_advantage_item_content_list_item {
  display: flex;
  align-items: center;
  z-index: 1;
}

.kernel_advantage_item_content_list_item_icon {
  width: 0.2rem;
  height: 0.2rem;
  background: url('../home/kernel_advantage_item_content_list_item_icon.png') no-repeat;
  background-size: 100%;
}

.kernel_advantage_item_content_list_item_info {
  width: 3.1rem;
  line-height: 0.3rem;
}

.kernel_advantage_item_content_img {
  width: 1.6rem;
  height: 1.6rem;
  position: absolute;
  left: 1.6rem;
  bottom: 0.4rem;
  z-index: 0;
}

.solution_content {
  width: 16.6rem;
  margin: 0 auto;
}

.solution_item {
  width: 2.3rem;
  height: 3.65rem;
  border-radius: 0.18rem;
  overflow: hidden;
  position: relative;
  transition: all 0.2s;
}

.solution_item_active {
  width: 3.5rem;
}

.solution_item_bg {
  width: 3.5rem;
  height: 3.65rem;
  background-color: pink;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
}

.solution_item_bg_mark {
  width: 100%;
  height: 3.65rem;
  position: absolute;
  background: rgba(255, 255, 255, 0.05);
  -webkit-backdrop-filter: blur(0.1rem);
  backdrop-filter: blur(0.1rem);
  transition: all 0.2s;
  left: 0;
  bottom: 0;
  z-index: 2;
}

.solution_item_bg_mark_active {
  height: 0.8rem;
}

.solution_item_icon {
  width: 0.5rem;
  height: 0.5rem;
}

.shopkeeping_system_list {
  width: 100%;
}

.shopkeeping_system_tabs {
  width: 16.24rem;
  border-bottom: 0.06rem solid #EBF1FF;
}

.shopkeeping_system_tab {
  width: 1.86rem;
  height: 1.12rem;
  position: relative;
}

.shopkeeping_system_tab_icon {
  width: 1.2rem;
  height: 1.2rem;
  position: absolute;
  left: 0.33rem;
}

.shopkeeping_system_tab_title {
  width: 100%;
  transition: all 0.2s;
  position: absolute;
  top: 0.2rem;
}

.shopkeeping_system_tab_line {
  height: 0.06rem;
  background: #0064FF;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: 0.12rem;
  transition: all 0.2s;
}

.shopkeeping_system_content {
  width: 100%;
  height: 7.84rem;
  background: linear-gradient( 180deg, rgba(245,248,255,0.8) 0%, rgba(244,246,250,0) 100%);
}

.shopkeeping_system_content_left {
  width: 3.36rem;
}

.shopkeeping_system_content_item {
  width: 3.36rem;
  height: 0.78rem;
  border-radius: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.shopkeeping_system_content_item_active {
  background: rgba(37,103,245,0.2);
}

.shopkeeping_system_content_item_icon {
  width: 0.42rem;
  height: 0.42rem;
}

.shopkeeping_system_content_right {
  width: 12.5rem;
  height: 7.04rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.shopkeeping_system_content_right_img {
  width: auto;
  height: 100%;
}

.our_advantage {
  height: 9.75rem;
  background-color: #98ADFF;
}

.our_advantage_content {
  width: 17.44rem;
  height: 7.06rem;
  background: rgba(255,255,255,0.7);
  border-radius: 0.14rem;
}

.our_advantage_content_left {
  width: 8.19rem;
}

.our_advantage_content_left_item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.our_advantage_content_left_item_title {
  height: 0.5rem;
  line-height: 0.5rem;
}

.our_advantage_content_left_item_dote {
  width: 0.29rem;
  height: 0.29rem;
  background: #98ADFF;
  border-radius: 0.185rem;
}

.our_advantage_content_left_item_desc {
  width: 7.5rem;
}

.our_advantage_content_left_item_desc_active {
  background-color: #F0F3FF;
}

.our_advantage_content_right {
  width: 5.73rem;
  height: 3.84rem;
}

.product_thumbnail {
  height: 5.2rem;
  background: #F9FAFE;
}

.product_thumbnail_item {
  width: 2.85rem;
  height: 4.4rem;
  background: linear-gradient( 180deg, rgba(255,255,255,0.25) 0%, rgba(255,255,255,0.7) 100%);
  border-radius: 0.18rem;
  border: 0.01rem solid #EFEFEF;
  overflow: hidden;
  margin: 0 0.11rem;
  padding: 0.3rem;
  opacity: 0.8;
  transition: all 0.2s;
  box-shadow: 0 0.02rem 0.13rem 0 rgba(255,166,50,0.07);
}

.product_thumbnail_item_active {
  width: 3.93rem;
  background: linear-gradient( 180deg, #0A57F3 0%, rgba(43,114,255,0) 100%);
  border-radius: 0.18rem 0.18rem 0 0;
  border: none;
  opacity: 1;
}

.product_thumbnail_item_btn {
  width: 1.4rem;
  height: 0.52rem;
  line-height: 0.52rem;
  color: #005CFF;
  background: #FFFFFF;
  box-shadow: 0.02rem -0.02rem 0.14rem 0 rgba(81,106,152,0.24);
  border-radius: 0.08rem;
  cursor: pointer;
}

.product_thumbnail_item_img {
  width: 3.93rem;
  height: 3.69rem;
}