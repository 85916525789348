.footer-wrop {
  /* height: 728px; */
  background: linear-gradient(135deg, #E5EEFF 0%, #EDF3FF 100%);
}

.footer-wrop .footer1 {
  padding: 0.6rem 0 0.35rem 1.8rem;
  display: flex;
}

.footer1-left {
  display: flex;
  flex-direction: column;
}

.pic {
  width: 1.3rem;
  cursor: pointer;
}

.pic .pic-name {
  text-align: center;
  line-height: 0.16rem;
  font-size: 0.2rem;
}

.footer-bottom {
  width: max-content;
  height: 0.72rem;
  background: rgba(255,255,255,0.5);
  border-radius: 0.36rem;
  margin-top: 0.5rem;
  padding: 0 0.24rem;
  display: flex;
  align-items: center;
}

.illustrate {
  display: flex;
  align-items: center;
  font-size: 0.18rem;
  color: #000000;
}

.illustrate .icon {
  width: 0.28rem;
  height: 0.28rem;
}

.illustrate-name {
  font-weight: 400;
  font-style: normal;
  text-transform: none;
}

.span {
  margin-left: 0.08rem;
}

.footer1-right {
  width: 4.7rem;
}

.footer1-right-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer1-right-header-title {
  font-size: 0.16rem;
  color: #000000;
}

.footer1-right-header-btn {
  cursor: pointer;
}

.footer1-right-item {
  padding: 0.15rem 0;
  font-weight: 400;
  font-size: 0.12rem;
  text-align: justify;
}

.footer-wrop .footer1 .footer1-two .footer1-con .title {
  font-weight: 600;
  font-size: 0.16rem;
  color: #000000;
  line-height: 0.22rem;
  margin-bottom: 0.25rem;
}

.footer-wrop .footer1 .footer1-two .footer1-con .name {
  font-weight: 400;
  font-size: 0.16rem;
  color: #000000;
  line-height: 0.22rem;
  margin-bottom: 0.25rem;
  cursor: pointer;
}

.footer-wrop .footer1 .footer1-three {
  height: 3.4rem;
}

.footer-wrop .footer1 .footer1-three .footer1-con .name {
  width: 4.6rem;
  font-weight: 400;
  font-size: 0.12rem;
  color: #000000;
  line-height: 0.17rem;
  padding: 0.14rem 0;
  cursor: pointer;
}

.footer-wrop .footer1 .footer1-three .footer1-con .line {
  border-bottom: 0.01rem solid #999999;
  opacity: 0.5;
}

.footer-wrop .footer1 .footer1-three .footer1-con .left {
  width: 0.32rem;
  font-weight: 600;
  font-size: 0.16rem;
  color: #000000;
  line-height: 0.22rem;
}

.footer-wrop .footer1 .footer1-three .footer1-con .right {
  font-weight: 400;
  font-size: 0.12rem;
  color: #000000;
  line-height: 0.22rem;
  cursor: pointer;
}

.footer-wrop .footer1 .footer1-three .footer1-con .right .img {
  width: 0.15rem;
  height: 0.15rem;
  vertical-align: text-bottom;
  margin-left: 0.02rem;
}

.footer-wrop .footer2 {
  padding: 0.25rem 1.9rem;
  height: 2.21rem;
  background: rgba(255, 255, 255, 0.5);
}

.footer-wrop .footer2 .footer2-top .block {
  width: 1.18rem;
  margin-bottom: 0.19rem;
}

.footer-wrop .footer2 .footer2-top .block .block-img-wrap {
  width: 1.18rem;
  height: 1.18rem;
  background: url("../../static/footer/footer2-top_bg.png") no-repeat;
  background-size: 100%;
  background-position: center;
}

.footer-wrop .footer2 .footer2-top .block .block-img-wrap:hover {
  background: url("../../static/footer/footer2-top_bg_active.png") no-repeat;
  background-size: 100%;
  background-position: center;
}

.footer-wrop .footer2 .footer2-top .block .block-img-wrap .block-img {
  width: 0.8rem;
  height: 0.8rem;
}

.footer-wrop .footer2 .footer2-top .block .block-name {
  height: 0.34rem;
  font-weight: 400;
  font-size: 0.22rem;
  color: #333333;
  line-height: 0.34rem;
  text-align: center;
}

.footer-wrop .footer3 {
  padding: 0.3rem 1.48rem 0 1.48rem;
}

.footer-wrop .footer3 .line {
  border-bottom: 0.01rem solid #999999;
  opacity: 0.5;
  margin-bottom: 0.19rem;
}

.footer-wrop .footer3 .footer3-name {
  height: 0.2rem;
  font-weight: 500;
  font-size: 0.14rem;
  color: #999999;
  line-height: 0.2rem;
}