.mobile_safe_header {
  width: 100vw;
  background: linear-gradient( 126deg, rgba(0,100,255,0.4) 0%, rgba(0,53,134,0.4) 100%);
  color: #ffffff;
  padding-bottom: 0.3rem;
}

.mobile_safe_header_img {
  width: 100vw;
}

.mobile_safe_header_title {
  font-size: 0.6rem;
  font-weight: 600;
  margin-left: 0.3rem;
  margin-top: 0.2rem;
}

.mobile_safe_header_desc {
  font-size: 0.3rem;
  margin-left: 0.3rem;
  margin-top: 0.2rem;
}

.mobile_safe_header_btn {
  width: 3rem;
  height: 1rem;
  line-height: 1rem;
  color: #005CFF;
  font-size: 0.3rem;
  margin-left: 0.3rem;
  margin-top: 0.2rem;
  background: #FFFFFF;
  box-shadow: 0.02rem -0.02rem 0.14rem 0 rgba(81,106,152,0.24);
  border-radius: 0.2rem;
  letter-spacing: 0.02rem;
}

.mobile_safe_desc_wrap {
  background-color: #ffffff;
  flex-wrap: wrap;
  justify-content: space-around;
  padding: 0.3rem;
}

.mobile_safe_desc_item {
  width: 45vw;
  font-size: 0.4rem;
}

.mobile_safe_desc_item_icon {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 0.3rem;
}

.mobile_safe_desc_item_title {
  font-weight: bold;
}

.mobile_safe_desc_item_desc {
  margin-top: 0.1rem;
}

.mobile_safe_modules_list {
  width: 100vw;
  padding: 0.4rem;
  background-color: #ffffff;
}

.mobile_safe_modules_item {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.mobile_safe_modules_img {
  width: 4rem;
  height: auto;
}

.mobile_safe_modules_content {
  width: 50%;
  justify-content: center;
  margin: 0.3rem;
}

.mobile_safe_modules_title {
  font-size: 0.5rem;
  font-weight: 600;
}

.mobile_safe_modules_desc {
  width: 50vw;
  font-size: 0.4rem;
  margin-top: 0.3rem;
}