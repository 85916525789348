.mobile_home_banner {
  width: 100vw;
  color: #ffffff;
  background: #287EFD;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0.4rem;
}

.mobile_home_banner_title {
  font-size: 0.8rem;
  font-weight: 600;
  margin-top: 0.6rem;
  letter-spacing: 0.05rem;
}

.mobile_home_banner_time,
.mobile_home_banner_desc {
  font-size: 0.4rem;
  margin-top: 0.2rem;
  font-weight: 400;
  letter-spacing: 0.02rem;
}

.mobile_home_tabs {
  width: 100vw;
  height: 1rem;
  background: linear-gradient( 90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 54%, rgba(255,255,255,0) 100%);
  color: #ffffff;
  border-radius: 0.2rem;
  --title-font-size: 0.35rem;
  --active-line-color: #fff;
  --active-title-color: #333333;
  margin-top: 0.4rem;
}

.mobile_home_swiper {
  width: 92vw;
  height: auto;
}

.mobile_home_swiper_card {
  width: 92vw;
  height: auto;
  background: #ffffff;
  color: #287EFD;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  border-radius: 0.2rem;
}

.mobile_offer_plan_wrap {
  width: 96vw;
  background-color: #ffffff;
  padding: 0.3rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  margin-top: 0.2rem;
  border-radius: 0.2rem;
}

.mobile_offer_plan_title {
  width: 100%;
  font-size: 0.5rem;
  font-weight: 600;
}

.mobile_offer_plan_card_bag {
  margin-top: 0.3rem;
}

.mobile_offer_plan_card {
  padding: 0.2rem 0;
}

.mobile_offer_plan_card_img {
  width: 3rem;
  height: 3rem;
  flex-shrink: 0;
}

.mobile_offer_plan_card_right {
  margin-left: 0.4rem;
  letter-spacing: 0.03rem;
}

.mobile_offer_plan_card_title {
  font-size: 0.45rem;
  font-weight: bold;
}

.mobile_offer_plan_card_desc {
  font-size: 0.35rem;
  margin-top: 0.3rem;
}

.mobile_offer_plan_card_plus {
  font-size: 0.4rem;
  font-weight: bold;
}

.mobile_team_performance_wrap {
  background-color: #AFCBFE;
  padding: 0.3rem 0;
  margin-top: 0.3rem;
}

.mobile_team_performance_title {
  font-size: 0.5rem;
  font-weight: 600;
}

.mobile_team_performance_desc {
  font-size: 0.35rem;
  margin-top: 0.1rem;
}

.mobile_team_performance_item {
  width: 90vw;
  flex-wrap: wrap;
}

.mobile_team_performance_item_card {
  width: 40vw;
  margin-top: 0.35rem;
}

.mobile_team_performance_item_card_title {
  font-size: 0.5rem;
  font-weight: 600;
}

.mobile_team_performance_item_card_desc {
  margin-top: 0.1rem;
}

.mobile_core_business {
  width: 100vw;
  margin: 0 auto;
  margin-top: 0.5rem;
  background: rgba(247,247,247,0.8);
}

.mobile_core_business_title {
  font-size: 0.5rem;
  font-weight: 600;
  margin-left: 0.3rem;
}

.mobile_core_business_wrap {
  margin-left: 0.3rem;
}

.mobile_core_business_card {
  width: 94vw;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.2rem;
  margin-top: 0.3rem;
  padding: 0.3rem;
}

.mobile_core_business_card_info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.mobile_core_business_card_title {
  font-size: 0.4rem;
  font-weight: 600;
}

.mobile_core_business_card_desc {
  font-size: 0.25rem;
  color: #3d3d3d;
  margin-top: 0.2rem;
  text-align: justify;
}

.mobile_core_business_card_remark {
  font-size: 0.35rem;
  margin-top: 0.3rem;
  position: relative;
}

.mobile_quotation_mark_top {
  font-size: 0.45rem;
  position: absolute;
  left: -0.2rem;
  top: -0.3rem;
}

.mobile_quotation_mark_bottom {
  font-size: 0.45rem;
  position: absolute;
  bottom: -0.3rem;
  right: -0.2rem;
}

.mobile_core_business_card_img {
  width: 45vw;
}

.mobile_common_basic_module {
  width: 94vw;
  margin: 0 auto;
  margin-top: 0.5rem;
}

.mobile_common_basic_module_wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.mobile_common_basic_module_title {
  font-size: 0.5rem;
  font-weight: 600;
}

.mobile_common_basic_module_desc {
  width: 80vw;
  font-size: 0.3rem;
  letter-spacing: 0.01rem;
  margin-top: 0.2rem;
}

.mobile_common_basic_module_card {
  width: 3rem;
  height: 2rem;
  background: linear-gradient( 270deg, #FFFFFF 0%, rgba(255,255,255,0.5) 100%);
  box-shadow: 0 0.02rem 0.13rem 0 rgba(255,166,50,0.07);
  border-radius: 0.2rem;
  margin-top: 0.3rem;
  padding-left: 0.3rem;
}

.mobile_common_basic_module_card_img {
  margin-top: 0.2rem;
}

.mobile_common_basic_module_card_title {
  margin-top: 0.1rem;
}

.mobile_our_member_say {
  width: 96vw;
  margin: 0 auto;
  margin-top: 0.5rem;
}

.mobile_our_member_say_title {
  font-size: 0.5rem;
  font-weight: 600;
}

.mobile_our_member_say_swiper {
  margin-top: 0.3rem;
}

.mobile_our_member_say_swiper_card {
  width: 96vw;
  background: rgba(255,255,255,0.8);
  border-radius: 0.2rem;
  overflow: hidden;
  padding: 0.3rem;
}

.mobile_our_member_say_swiper_card_img {
  width: 90vw;
  height: auto;
}

.mobile_our_member_say_swiper_card_info_title,
.mobile_our_member_say_swiper_card_info_desc {
  align-items: flex-start;
}

.mobile_our_member_say_swiper_card_info_title {
  font-size: 0.4rem;
  margin-top: 0.2rem;
}

.mobile_our_member_say_swiper_card_info_desc {
  font-size: 0.3rem;
  margin-top: 0.1rem;
  line-height: 0.4rem;
}

.mobile_our_member_say_dote {
  width: 0.2rem;
  height: 0.2rem;
  background: #98ADFF;
  border-radius: 50%;
  flex-shrink: 0;
  margin-top: 0.1rem;
  margin-right: 0.2rem;
}

.mobile_header_member_select {
  width: 96vw;
  margin: 0 auto;
  margin-top: 0.3rem;
  background: rgba(255,255,255,0.8);
  border-radius: 0.2rem;
  padding: 0.3rem;
}

.mobile_header_member_select_title {
  font-size: 0.5rem;
  font-weight: 600;
}

.mobile_header_member_select_title span {
  font-size: 0.35rem;
}

.mobile_header_member_select_desc {
  display: flex;
  align-items: center;
  margin-top: 0.3rem;
}

.mobile_header_member_select_desc_item {
  display: flex;
  align-items: baseline;
  margin-right: 0.4rem;
}

.mobile_header_member_select_desc_item_num {
  font-size: 0.6rem;
  color: #0066D4;
  font-weight: 600;
}

.mobile_free_a_week {
  width: 100vw;
  height: 2rem;
  background: #216EF6;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.mobile_free_a_week_left {
  display: flex;
  align-items: baseline;
  font-size: 0.32rem;
  color: #ffffff;
}

.mobile_free_a_week_btn {
  width: 2.5rem;
  height: 1rem;
  line-height: 1rem;
  background-color: #ffffff;
  border-radius: 0.5rem;
  color: #005CFF;
}

.mobile_core_advantage {
  width: 96vw;
  margin: 0 auto;
  margin-top: 0.5rem;
}

.mobile_core_advantage_title {
  font-size: 0.5rem;
  font-weight: 600;
  padding-left: 0.3rem;
}

.mobile_core_advantage_desc {
  width: 93vw;
  font-size: 0.35rem;
  margin-top: 0.2rem;
  padding-left: 0.3rem;
}

.mobile_core_advantage_list_item {
  width: 96vw;
  background-color: #ffffff;
  border-radius: 0.2rem;
  margin-top: 0.3rem;
  padding: 0.3rem 0.4rem;
  display: flex;
  align-items: center;
}

.mobile_core_advantage_list_item_icon {
  width: 3rem;
  height: 3rem;
}

.mobile_core_advantage_list_item_info {
  width: 6rem;
}

.mobile_core_advantage_list_item_title {
  font-size: 0.5rem;
  position: relative;
}

.mobile_core_advantage_list_item_desc {
  font-size: 0.35rem;
  display: flex;
  align-items: center;
}

.mobile_core_advantage_list_item_title_icon {
  width: 0.6rem;
  height: 0.6rem;
  background: url('../../../static/home/kernel_advantage_item_content_title_icon.png') no-repeat;
  background-size: 100%;
  position: absolute;
  left: -0.4rem;
  top: -0.25rem;
}

.mobile_core_advantage_list_item_desc_icon {
  width: 0.35rem;
  height: 0.35rem;
  background: url('../../../static/home/kernel_advantage_item_content_list_item_icon.png') no-repeat;
  background-size: 100%;
  margin-right: 0.1rem;
  flex-shrink: 0;
}

.mobile_solution_wrap {
  width: 96vw;
  margin: 0 auto;
  margin-top: 0.5rem;
}

.mobile_solution_title {
  font-size: 0.5rem;
  font-weight: 600;
}

.mobile_solution_desc {
  font-size: 0.35rem;
  margin-top: 0.2rem;
}

.mobile_solution_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.mobile_solution_item {
  width: 4.5rem;
  height: 4.5rem;
  border-radius: 0.2rem;
  margin-top: 0.2rem;
  overflow: hidden;
  position: relative;
}

.mobile_solution_item_info {
  width: 4.5rem;
  height: 1rem;
  background: rgba(0,0,0,0.3);
  color: #ffffff;
  font-size: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
}

.mobile_solution_item_desc {
  width: 0.8rem;
  height: 0.8rem;
  margin-left: 0.3rem;
}

.mobile_manage_system {
  width: 100vw;
  background-color: #ffffff;
  margin: 0 auto;
  margin-top: 0.3rem;
  padding-top: 0.5rem;
}

.mobile_manage_system_title {
  font-size: 0.5rem;
  font-weight: 600;
}

.mobile_manage_system_tabs {
  width: 100vw;
  height: 2rem;
  background: linear-gradient( 90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.3) 54%, rgba(255,255,255,0) 100%);
  color: #000000;
  border-radius: 0.2rem;
  --title-font-size: 0.35rem;
  --active-line-color: #0064FF;
  --active-title-color: #000000;
  margin-top: 0.4rem;
}

.mobile_manage_system_tabs_icon {
  width: 1rem;
  height: 1rem;
}

.mobile_manage_system_swiper {
  width: 100%;
  padding-bottom: 0.3rem;
}

.mobile_manage_system_swiper_card {
  display: flex;
  justify-content: space-around;
}

.mobile_manage_system_swiper_card_side_bar {
  width: 30vw;
  font-size: 0.4rem;
}

.mobile_manage_system_swiper_card_side_bar_item_title {
  margin-left: 0.1rem;
}

.mobile_manage_system_swiper_card_side_bar_item {
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 0.1rem;
}

.mobile_manage_system_swiper_card_side_bar_item_icon {
  width: 0.8rem;
  height: 0.8rem;
}

.mobile_manage_system_swiper_card_side_img {
  width: 60vw;
  height: 4rem;
  margin-left: 0.2rem;
}

.mobile_our_advantage {
  width: 100vw;
  background: #ffffff;
  margin: 0 auto;
  margin-top: 0.3rem;
  padding-top: 0.3rem;
}

.mobile_our_advantage_title {
  font-size: 0.5rem;
  font-weight: 600;
}

.mobile_our_advantage_desc {
  font-size: 0.35rem;
  margin-top: 0.2rem;
}

.mobile_our_advantage_list {
  width: 96vw;
  padding: 0.3rem;
}

.mobile_our_advantage_list img {
  width: 100%;
}

.mobile_our_advantage_item_title {
  display: flex;
  align-items: center;
  font-size: 0.4rem;
  font-weight: 600;
  margin-top: 0.2rem;
}

.mobile_our_advantage_dote {
  width: 0.3rem;
  height: 0.3rem;
  background-color: #98ADFF;
  border-radius: 50%;
  margin-right: 0.1rem;
}

.mobile_our_advantage_item_desc {
  font-size: 0.35rem;
  margin-top: 0.1rem;
}

.mobile_home_product_wrap {
  width: 100vw;
  margin: 0 auto;
  margin-top: 0.3rem;
}

.mobile_home_product_item {
  background-color: #ffffff;
  padding: 0.3rem;
}

.mobile_home_product_item_title {
  font-size: 0.5rem;
  font-weight: 600;
}

.mobile_home_product_item_desc {
  font-size: 0.35rem;
}

.mobile_home_product_item_img {
  width: 96vw;
}