.none {
  display: none;
}

.block {
  display: block;
}

.header-box {
  background-color: white;
  width: 100%;
  height: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 999;
  box-shadow: 0 0.01rem 0.02rem 0 rgba(0, 0, 0, 0.03), 0 0.01rem 0.06rem -0.01rem rgba(0, 0, 0, 0.02), 0 0.02rem 0.04rem 0 rgba(0, 0, 0, 0.02);
}

.header-box .header-left {
  /* padding: 0.1rem 0; */
}

.header-box .header-left .logo {
  width: 0.46rem;
  height: 0.46rem;
}

.header-box .header-left .name {
  font-size: 0.2rem;
  font-weight: 500;
  color: #333333;
  line-height: 0.46rem;
  margin-left: 0.2rem;
  margin-bottom: 0;
  margin-right: 0.6rem;
}

.header-box .header-left .header-tab {
  position: relative;
  margin: 0 0.2rem;
}

.header_drop_list {
  width: 3.52rem;
  background: #ffffff;
  box-shadow: 0 0.02rem 0.15rem 0 rgba(0,26,77,0.16);
  border-radius: 0 0 0.12rem 0.12rem;
  z-index: 1000;
  overflow: hidden;
  position: absolute;
  top: 0.69rem;
  left: -1.2rem;
}

.header_drop_list_item {
  width: 3.52rem;
  height: 0.8rem;
  padding: 0.05rem 0;
  align-items: center;
  cursor: pointer;
}

.header_drop_list_item:hover {
  background: #f5f5f5;
}

.header_drop_list_item_box {
  width: 0.6rem;
  height: 0.6rem;
  background: #F7F8FA;
  border-radius: 0.08rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header_drop_list_item_icon {
  width: 0.28rem;
  height: 0.28rem;
}

.header-box .header-left .header-tab .header-title {
  font-size: 0.18rem;
  font-weight: 400;
  color: #333333;
  line-height: 0.46rem;
  text-align: center;
  cursor: pointer;
  position: relative;
}

.header-box .header-left .header-tab .header-title:hover {
  color: #206EF7;
}

.header-box .header-left .header-tab .header-title-choose {
  color: #206EF7;
}

.header-box .header-left .header-tab .down {
  width: 0.15rem;
  height: 0.15rem;
  margin-top: 0.16rem;
  margin-left: 0.05rem;
  transition: all 0.2s;
}

.down_active {
  transform: rotate(180deg);
}

.header-box .header-left .header-tab .line {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  bottom: -0.06rem;
  width: 0.35rem;
  height: 0.03rem;
  background: linear-gradient(90deg, #4588FF 0%, #206EF7 100%);
}

.header-box .header-left .header-tab .bg {
  background: white;
}

.header-box .header-right .call-icon {
  width: 0.55rem;
  height: 0.55rem;
}

.header-box .header-right .call {
  font-size: 0.2rem;
  color: #206EF7;
  margin-left: 0.12rem;
}

.header-box .header-right .call-name {
  color: #333333;
}

.header-box .header-right .button {
  width: 1.74rem;
  height: 0.66rem;
  background: linear-gradient(270deg, #4588FF 0%, #206EF7 100%);
  border-radius: 0.12rem;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 0.66rem;
  font-size: 0.24rem;
  text-align: center;
  cursor: pointer;
  margin-left: 0.4rem;
}

.header-dragon {
  display: flex;
  align-items: center;
  cursor: pointer;
}