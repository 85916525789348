.mobile_footer_page {
  width: 100vw;
  padding-top: 0.3rem;
}

.mobile_footer_download_card {
  width: 100vw;
  padding: 0.3rem;
  background: #C3D5FF;
}

.mobile_footer_download_title {
  height: 0.8rem;
  font-size: 0.45rem;
  color: rgba(0,0,0,1);
  line-height: 0.8rem;
  display: flex;
  align-items: baseline;
}

.mobile_footer_download_title_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile_footer_download_title_desc {
  font-size: 0.4rem;
  color: rgba(0,0,0,0.8);
}

.mobile_footer_download_item_wrap {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.3rem;
}

.mobile_footer_download_item {
  display: flex;
  align-items: center;
  margin-top: 0.3rem;
}

.mobile_footer_download_item_icon {
  width: 2rem;
  height: 2rem;
  background-color: pink;
}

.mobile_footer_download_item_content {
  width: 2.5rem;
  margin-left: 0.2rem;
}

.mobile_footer_download_item_title {
  font-size: 0.4rem;
}

.mobile_footer_download_item_desc {
  font-size: 0.3rem;
}

.mobile_footer_download_btn {
  width: 3rem;
  height: 1rem;
  line-height: 1rem;
  color: #ffffff;
  font-size: 0.4rem;
  letter-spacing: 0.02rem;
  background: linear-gradient( 90deg, #4588FF 0%, #206EF7 100%);
  box-shadow: 0.02rem -0.02rem 0.14rem 0rem rgba(81,106,152,0.24);
  border-radius: 0.5rem;
}

.footer_gap {
  width: 100vw;
  height: 0.3rem;
  background: #ffffff;
}

.mobile_footer_postcard {
  width: 100vw;
  background: linear-gradient( 135deg, #E5EEFF 0%, #EDF3FF 100%);
}

.mobile_footer_postcard_er_code {
  width: 94vw;
  margin-top: 0.3rem;
  font-size: 0.35rem;
  display: flex;
  align-items: center;
}

.mobile_footer_postcard_er_code_wrap {
  margin-right: 0.8rem;
}

.mobile_footer_postcard_tel,
.mobile_footer_postcard_com,
.mobile_footer_postcard_add {
  width: 94vw;
  font-size: 0.4rem;
  display: flex;
  align-content: center;
}

.mobile_footer_postcard_list {
  width: 94vw;
  padding-bottom: 0.3rem;
  margin-top: 0.3rem;
}

.mobile_footer_postcard_list_item_title {
  font-size: 0.4rem;
  font-weight: 600;
  margin-top: 0.3rem;
}

.mobile_footer_postcard_list_item_content_wrap {
  display: flex;
  font-size: 0.35rem;
}

.mobile_footer_postcard_list_item_content {
  margin-right: 0.2rem;
  margin-top: 0.2rem;
}

.mobile_footer_modules {
  width: 100vw;
  background: rgba(255,255,255,0.5);
  padding: 0.3rem 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.mobile_footer_modules_item {
  width: 2rem;
  height: 2rem;
  background: url("../../static/footer/footer2-top_bg.png") no-repeat;
  background-size: 100%;
  background-position: center;
  margin: 0 0.2rem 0.4rem 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.mobile_footer_modules_item_icon {
  width: 1.4rem;
  height: 1.4rem;
}

.mobile_footer_modules_item_title {
  position: absolute;
  bottom: -0.4rem;
}

.mobile_footer_copyright_line {
  width: 98vw;
  height: 0.01rem;
  background: #999999;
  margin: 0.3rem 0;
}

.mobile_footer_copyright {
  width: 98vw;
  display: flex;
  color: #999999;
  margin-bottom: 0.3rem;
}