.thirdpartnar_mobile {
  background: #ffffff;
  padding-bottom: 0.6rem;
}

.mobile_third_partner_img {
  width: 100vw;
  height: auto;
}

.mobile_third_partner_btn_wrap {
  width: 100vw;
  height: 2rem;
  background: #FFFFFF;
  box-shadow: 0 -0.02rem 0.1rem 0 rgba(0,0,0,0.06);
  border-radius: 0 0 0 0;
  position: fixed;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile_third_partner_btn {
  width: 8rem;
  height: 1.2rem;
  background: linear-gradient( -90deg, #FF7F00 0%, #FF4800 100%), #007AFF;
  color: #ffffff;
  font-size: 0.4rem;
  border-radius: 0.6rem;
  line-height: 1.2rem;
  letter-spacing: 0.02rem;
  font-weight: 700;
}

.mobile_third_partner_success_wrap {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile_third_partner_popup_card {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile_free_get_form_input {
  width: 8rem;
  height: 1.2rem;
  line-height: 1.2rem;
  font-size: 0.4rem;
  color: rgba(0,0,0,0.76);
  background: #FFF4ED;
  border-radius: 0.6rem;
  border: 0.01rem solid #FF7B00;
  padding-left: 0.2rem;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  margin-top: 0.35rem;
  padding-left: 0.3rem;
}

.mobile_free_get_form_input option {
  width: 6rem;
  font-size: 0.15rem;
}

.mobile_free_get_button {
  width: 8rem;
  height: 1.2rem;
  line-height: 1.2rem;
  font-size: 0.4rem;
  background: linear-gradient( -90deg, #FF7F00 0%, #FF4800 100%), #007AFF;
  border-radius: 0.6rem;
  letter-spacing: 0.02rem;
  font-weight: 700;
  margin-top: 0.5rem;
}

.mobile_third_partner_success_card {
  width: 90vw;
  padding: 0.3rem 0 0.5rem 0;
  background: #FFFFFF;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.mobile_third_partner_success_title {
  font-size: 0.5rem;
  color: #000000;
  margin-top: 0.6rem;
  font-weight: normal;
}

.mobile_third_partner_success_icon {
  width: 3rem;
  height: 2.8rem;
  margin-top: 0.5rem;
}

.mobile_third_partner_success_desc {
  font-weight: 400;
  font-size: 0.4rem;
  color: #000000;
  margin-top: 0.3rem;
}

.mobile_third_partner_success_close {
  width: 7rem;
  height: 1.2rem;
  line-height: 1.2rem;
  background: linear-gradient( 90deg, #FF7F00 0%, #FF4800 100%), #007AFF;
  border-radius: 0.6rem;
  margin-top: 1rem;
  font-size: 0.4rem;
  color: #ffffff;
  letter-spacing: 0.02rem;
  font-weight: 600;
}

.mobile_third_partner_success_hotline {
  width: 1rem;
  height: 1rem;
  position: absolute;
  bottom: -1.5rem;
}

.adm-toast-mask .adm-toast-main {
  max-width: max-content;
}

.adm-auto-center-content {
  font-size: 0.4rem;
}