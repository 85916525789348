.software {
  background-color: #fff;
}

.software .software-content {
  background: linear-gradient(360deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 100%);
}

.software_header {
  padding-top: 0.9rem;
  background: url('../../static/images/software/software_header_bg.png') no-repeat;
  background-size: 100%;
}

.software .software-content .title {
  font-weight: 600;
  font-size: 0.6rem;
  color: #000000;
  text-align: center;
}

.software .software-content .tip {
  height: 0.42rem;
  font-weight: 400;
  font-size: 0.3rem;
  color: #000000;
  line-height: 0.42rem;
  letter-spacing: 0.02rem;
  text-align: center;
  margin: 0.34rem 0 0.56rem 0;
}

.software .software-content .software-downloads {
  padding: 0 3.04rem;
}

.software .software-content .software-downloads .item {
  width: 2.98rem;
  height: 3.74rem;
  background: linear-gradient(315deg, #2A73F3 0%, #90B8FF 100%);
  box-shadow: 0 0.05rem 0.1rem 0 rgba(0, 0, 0, 0.15), inset 0.02rem 0.02rem 0 0 #FFFFFF;
  border-radius: 0.28rem;
  margin: 0 auto;
  text-align: center;
}

.software .software-content .software-downloads .item .img {
  width: 1rem;
  height: 1rem;
}

.software .software-content .software-downloads .item .name {
  height: 0.34rem;
  font-weight: 600;
  font-size: 0.24rem;
  color: #FFFFFF;
  line-height: 0.34rem;
}

.software .software-content .software-downloads .item .button {
  width: 1.4rem;
  height: 0.5rem;
  border-radius: 1rem;
  border: 0.02rem solid #FFFFFF;
  font-weight: 400;
  font-size: 0.22rem;
  color: #FFFFFF;
  line-height: 0.5rem;
  margin: 0 auto;
  cursor: pointer;
}

.software .software-content .software-downloads .item .button:hover {
  color: #0053E4;
  background: #FFFFFF;
}

.software .software-content .software-function {
  height: 0.82rem;
  margin: 1rem auto;
  padding: 0 3.95rem;
}

.software .software-content .software-function .item {
  width: 0.8rem;
  text-align: center;
}

.software .software-content .software-function .item .img {
  width: 0.42rem;
  height: 0.42rem;
  margin-bottom: 0.12rem;
}

.software .software-content .software-function .item .name {
  height: 0.28rem;
  font-weight: 400;
  font-size: 0.2rem;
  color: rgba(0, 0, 0, 0.8);
  line-height: 0.28rem;
}

.function_image {
  width: 11.48rem;
  height: 6rem;
  margin: 0 auto;
  margin-bottom: 0.87rem;
}

.software .software-content .software-more {
  height: 0.56rem;
  font-weight: 400;
  font-size: 0.4rem;
  color: #000000;
  line-height: 0.56rem;
  letter-spacing: 0.02rem;
  text-align: center;
  margin-bottom: 0.42rem;
}

.buy_path_wrap {
  height: 5.51rem;
  background-color: #EBF1FF;
}

.buy_path_card {
  width: 13.28rem;
  height: 4.94rem;
  background: rgba(255,255,255,0.2);
  border-radius: 0.2rem;
  border: 0.02rem solid #FFFFFF;
}

.buy_path_card_wrap {
  width: 12.84rem;
  height: 4.5rem;
  background: rgba(255,255,255,0.3);
  border-radius: 0.2rem;
}

.buy_path_card_item {
  width: 3.42rem;
  height: 3.74rem;
  background: #FFFFFF;
  box-shadow: 0.02rem 0.02rem 0.18rem 0 rgba(21,53,128,0.08), inset 0.02rem 0.02rem 0 0 #FFFFFF;
  border-radius: 0.2rem;
  margin: 0 0.2rem;
}

.buy_path_card_item_icon {
  width: 0.94rem;
  height: 0.94rem;
}

.buy_path_card_item_btn {
  width: 1.72rem;
  height: 0.64rem;
  line-height: 0.64rem;
  background: linear-gradient( 90deg, #4588FF 0%, #206EF7 100%);
  border-radius: 0.32rem;
  cursor: pointer;
}