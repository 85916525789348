.mobile_product_banner {
  width: 100vw;
  color: #ffffff;
  background: #287EFD;
  display: flex;
  flex-direction: column;
  padding-top: 0.8rem;
  padding-bottom: 0.4rem;
  padding-left: 0.3rem;
}

.mobile_product_banner_title {
  font-size: 0.6rem;
  font-weight: 600;
}

.mobile_product_banner_desc {
  font-size: 0.4rem;
  margin-top: 0.4rem;
  font-weight: 400;
}

.mobile_product_audio {
  width: 100vw;
}

.mobile_product_modules {
  width: 100vw;
  margin-top: 0.3rem;
}

.mobile_product_module_item {
  width: 96vw;
  border-radius: 0.3rem;
  margin-bottom: 0.35rem;
  box-shadow: 0.02rem 0.02rem 0.18rem 0rem rgba(21,53,128,0.08), inset 0.02rem 0.02rem 0 0 #FFFFFF;
}

.mobile_product_module_item_left {
  width: 100%;
  font-size: 0.6rem;
  padding: 0.15rem;
}

.mobile_product_module_item_title {
  display: flex;
  align-items: center;
  margin-top: 0.4rem;
}

.mobile_product_module_item_desc {
  font-size: 0.4rem;
  text-align: justify;
  margin-top: 0.3rem;
}

.mobile_product_module_item_title_icon {
  width: 0.6rem;
  height: 0.6rem;
  flex-shrink: 0;
  margin-right: 0.2rem;
}

.mobile_product_module_item_right {
  width: 100%;
  height: auto;
  background-color: #ffffff;
  border-radius: 0.3rem;
  margin-top: 0.1rem;
  overflow: hidden;
}

.mobile_product_module_item_tags {
  margin-top: 0.3rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.mobile_product_module_item_tag {
  font-size: 0.4rem;
  font-weight: 600;
  margin-right: 0.3rem;
}

.mobile_product_module_item_buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 0.3rem;
  margin-bottom: 0.4rem;
}

.mobile_product_module_item_button_left,
.mobile_product_module_item_button_right {
  width: 2rem;
  height: 0.8rem;
  line-height: 0.8rem;
  font-size: 0.3rem;
  border-radius: 0.18rem;
}

.mobile_product_module_item_button_left {
  background: linear-gradient( 90deg, #4588FF 0%, #206EF7 100%);
  box-shadow: 0.02rem 0 0.14rem 0 rgba(81,106,152,0.14);
  color: #FFFFFF;
}

.mobile_product_module_item_button_right {
  background: #FFFFFF;
  color: #005CFF;
  border: 0.01rem solid #005CFF;
}

.mobile_version_wrap {
  background-color: #FFFFFF;
}

.mobile_version_title {
  font-size: 0.6rem;
  margin-top: 0.5rem;
}

.mobile_version_desc {
  font-size: 0.3rem;
  margin-top: 0.3rem;
}

.mobile_version_content {
  flex-wrap: wrap;
  margin-top: 0.3rem;
}

.mobile_version_content_item {
  width: 25vw;
}

.mobile_version_content_item_icon {
  width: 1.4rem;
  height: 1.4rem;
  margin-top: 0.3rem;
}

.mobile_version_detail_card_price {
  display: flex;
  align-items: baseline;
  font-size: 0.6rem;
}

.mobile_version_detail_card {
  width: 90vw;
  box-shadow: 0rem 0.02rem 0.15rem 0rem rgba(0,26,77,0.16);
  border-radius: 0.2rem;
  margin-top: 0.5rem;
  padding-bottom: 0.6rem;
}

.mobile_version_detail_card_header {
  width: 100%;
  height: 0.5rem;
  background-color: #FF3535;
  border-radius: 0.2rem 0.2rem 0 0;
}

.mobile_version_detail_card_content_title {
  font-size: 0.6rem;
  margin-top: 0.4rem;
}

.mobile_version_detail_card_content_desc {
  font-size: 0.35rem;
  color: #3D3D3D;
  margin-top: 0.3rem;
  letter-spacing: 0.01rem;
}

.mobile_version_detail_card_tip {
  width: 80%;
  height: 1rem;
  background: rgba(0,0,0,0.04);
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #387AF7;
  font-size: 0.4rem;
  letter-spacing: 0.02rem;
}

.mobile_version_detail_card_item {
  width: 80%;
  font-size: 0.4rem;
  margin-top: 0.3rem;
  display: flex;
  align-items: center;
}

.mobile_version_detail_card_item_text {
  margin-left: 0.2rem;
  color: #3D3D3D;
}

.mobile_version_detail_card_content_btn_wrap {
  width: 100%;
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile_version_detail_card_content_btn1,
.mobile_version_detail_card_content_btn2 {
  width: 3rem;
  height: 1rem;
  line-height: 1rem;
  font-size: 0.4rem;
  border-radius: 0.1rem;
}

.mobile_version_detail_card_content_btn1 {
  color: #FFFFFF;
  background: linear-gradient( -90deg, #4588FF 0%, #206EF7 100%);
}

.mobile_version_detail_card_content_btn2 {
  border: 0.02rem solid #4588FF;
  color: #4588FF;
  margin-left: 0.4rem;
}

.mobile_version_detail_card_ask_btn {
  width: 3.5rem;
  height: 1.2rem;
  margin: 0.3rem 0;
  font-size: 0.45rem;
  line-height: 1.2rem;
  color: #FFFFFF;
  background: linear-gradient( 90deg, #4588FF 0%, #206EF7 100%);
  box-shadow: 0.02rem 0 0.14rem 0 rgba(81,106,152,0.14);
  border-radius: 0.6rem;
}

.mobile_version_table_wrap {
  width: 90vw;
  margin-top: 0.4rem;
  border-radius: 0.24rem;
  border: 0.02rem solid #DEEAFF;
}

.mobile_version_table_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  position: sticky;
  top: 0rem;
}

.mobile_version_table_header_item_img {
  width: 20vw;
  height: 2rem;
  text-align: center;
  line-height: 2rem;
  font-size: 0.4rem;
  color: white;
  background-color: #495273;
  border-radius: 0.24rem;
}

.mobile_version_table_header_item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mobile_version_table_header_item_icon {
  width: 0.6rem;
  height: 0.6rem;
}

.mobile_version_table_header_item_price {
  display: flex;
  align-items: baseline;
}

.mobile_version_table_header_item_price p {
  font-size: 0.5rem;
}

.mobile_version_table_header_item_desc {
  width: 2rem;
}

.mobile_function_module_table {
  display: flex;
  flex-direction: column;
}

.mobile_function_module_table_title {
  align-items: center;
  font-size: 0.4rem;
}

.mobile_function_module_table_title_icon {
  width: 0.36rem;
  height: 0.36rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile_function_module_table_body {
  display: flex;
  flex-direction: column;
}

.mobile_function_module_table_line {
  width: 100%;
  height: 0.7rem;
  font-size: 0.35rem;
}

.mobile_function_module_table_line_name {
  width: 2.9rem;
}

.mobile_function_module_table_line_icon1,
.mobile_function_module_table_line_icon2,
.mobile_function_module_table_line_icon3 {
  width: 2.9rem;
  color: #3377FF;
}

.mobile_function_module_table_line_check_img {
  width: 0.4rem;
  height: 0.4rem;
  background: #3377FF;
  border-radius: 50%;
}

.mobile_version_selection_guide_item {
  width: 88vw;
  align-items: center;
}

.mobile_version_selection_guide_item_icon {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 50%;
  background-color: #DEEAFF;
  overflow: hidden;
  flex-shrink: 0;
}

.mobile_version_selection_guide_item_content {
  max-width: 70vw;
  color: #333333;
  padding: 0.3rem;
  background: #FFFFFF;
  box-shadow: 0.02rem 0.04rem 0.15rem 0 rgba(0,0,0,0.15);
  border-radius: 0 0.2rem 0.2rem 0.2rem;
}

.mobile_version_selection_guide_item_content_right {
  color: #ffffff;
  background: linear-gradient( 90deg, #4588FF 0%, #206EF7 100%);
  box-shadow: 0.02rem 0.04rem 0.15rem 0 rgba(0,62,179,0.14);
  border-radius: 0.2rem 0 0.2rem 0.2rem;
}