.header_wrap {
  width: 100vw;
  height: 1.5rem;
  font-size: 0.45rem;
  padding: 0.3rem;
  color: #333;
  background-color: #ffffff;
  box-shadow: 0 0.01rem 0.02rem 0 rgba(0, 0, 0, 0.03), 0 0.01rem 0.06rem -0.01rem rgba(0, 0, 0, 0.02), 0 0.02rem 0.04rem 0 rgba(0, 0, 0, 0.02);
  position: fixed;
  top: 0;
  z-index: 999;
}

.mobile_header_logo {
  width: 0.8rem;
  height: 0.8rem;
}

.mobile_header_name {
  margin-left: 0.2rem;
}

.mobile_order_list_icon {
  width: 0.6rem;
  height: 0.4rem;
}

.mobile_order_list_close {
  width: 100%;
  font-size: 0.5rem;
  padding: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mobile_order_list_wrap {
  margin-top: 1rem;
}

.mobile_order_list_item_item {
  min-height: 1rem;
}

.mobile_order_list_item {
  padding: 0 0.4rem;
  padding-top: 0.1rem;
  font-size: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.mobile_order_list_item_children {
  height: 1rem;
  padding: 0 0.4rem;
  padding-left: 0.65rem;
  font-size: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}