.success {
  width: 100%;
  background-color: #ffffff;
}

.success_audio_wrap {
  width: 15.59rem;
  height: 9.54rem;
  background: #F0F5FF;
  border-radius: 0.2rem;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.success_audio_list_warp {
  width: 15.2rem;
  height: 1.9rem;
  margin: 0.7rem auto;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  align-items: center;
  grid-gap: 0.3rem;
  gap: 0.3rem;
}

.success_audio_list_warp::-webkit-scrollbar {
  width: 0.05rem;
  height: 0.05rem;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 0.1rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 0.1rem;
  background: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgb(219, 219, 219);
}

.success_audio_list_item {
  width: 2.2rem;
  height: 1.7rem;
  font-size: 0.18rem;
  color: #000000;
  position: relative;
}

.success_audio_list_item_pop {
  width: 2.2rem;
  height: 1.24rem;
  background: rgba(0,0,0,0);
  cursor: pointer;
  position: absolute;
  top: 0;
}

.success_audio_play_mark {
  width: 1.05rem;
  height: 0.34rem;
  line-height: 0.34rem;
  background: #206EF7;
  border-radius: 0.04rem 0 0.1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: absolute;
  left: 0;
  top: 0;
}

.success_audio_play_mark_dot {
  width: 0.1rem;
  height: 0.1rem;
  background: #FFFFFF;
  border-radius: 0.05rem;
}

.success_audio_list_item_pop_icon {
  width: 0.28rem;
  height: 0.28rem;
  position: absolute;
  left: 0.06rem;
  bottom: 0.06rem;
}

.success_audio_list_item_video {
  width: 2.2rem;
  height: 1.24rem;
}

.success_audio {
  width: 15.19rem;
  height: 8.52rem;
}

.success_module_item {
  width: 100%;
}

.success_module_item_left {
  width: 7.7rem;
}

.success_module_item_title {
  display: flex;
  align-items: center;
}

.success_module_item_desc {
  width: 7.28rem;
  line-height: 0.4rem;
  text-align: justify;
}

.success_module_item_title_icon {
  width: 0.22rem;
  height: 0.22rem;
  background: #98ADFF;
  border-radius: 50%;
  flex-shrink: 0;
}

.success_module_item_right {
  width: 6.32rem;
  height: 4.88rem;
  margin: 1rem 0;
}