.mobile_success_modules {
  width: 100vw;
  margin-top: 0.3rem;
}

.mobile_success_module_item {
  width: 96vw;
  border-radius: 0.3rem;
  margin-bottom: 0.35rem;
  box-shadow: 0.02rem 0.02rem 0.18rem 0rem rgba(21,53,128,0.08), inset 0.02rem 0.02rem 0 0 #FFFFFF;
}

.mobile_success_module_item_left {
  width: 100%;
  font-size: 0.5rem;
  padding: 0.15rem;
}

.mobile_success_module_item_title {
  display: flex;
  align-items: center;
  margin-top: 0.2rem;
}

.mobile_success_module_item_desc {
  font-size: 0.4rem;
  text-align: justify;
  margin-top: 0.2rem;
}

.mobile_success_module_item_title_icon {
  width: 0.35rem;
  height: 0.35rem;
  background: #98ADFF;
  border-radius: 50%;
  flex-shrink: 0;
  margin-right: 0.2rem;
}

.mobile_success_module_item_right {
  width: 100%;
  height: auto;
}

.mobile_success_module_item_right img {
  width: 100%;
  height: auto;
}