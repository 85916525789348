.page_mobile {
  width: 100vw;
  height: 100vh;
  background: linear-gradient( 270deg, rgba(226,236,255,0.8) 0%, rgba(255,255,255,0) 100%);
  font-family: PingFang SC, PingFang SC;
  overflow-y: auto;
}

.mobile_about_us_wrap {
  padding-bottom: 0.3rem;
}

.mobile_about_me_introduce {
  padding-left: 0.25rem;
}

.mobile_about_me_introduce_img1 {
  width: 100vw;
}

.mobile_about_me_introduce_title {
  font-size: 0.5rem;
  margin-top: 0.2rem;
}

.mobile_about_me_introduce_item {
  align-items: flex-start;
  font-size: 0.4rem;
  margin-top: 0.4rem;
}

.mobile_about_me_introduce_item_illustrate {
  width: 85vw;
  margin-left: 0.2rem;
  text-align: justify;
}

.mobile_about_us_line {
  width: 100vw;
  height: 0.2rem;
  background: #ffffff;
}

.mobile_about_me_banner_warp {
  padding-bottom: 0.8rem;
}

.mobile_about_me_banner_title {
  font-size: 0.6rem;
  margin-top: 0.4rem;
}

.mobile_about_me_banner_desc {
  width: 90vw;
  font-size: 0.4rem;
  margin-top: 0.2rem;
}

.mobile_about_me_banner_swiper {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-top: 0.3rem;
}

.about_me_banner_card_item_img {
  width: 45vw;
  height: auto;
}

.mobile_about_me_banner_time_lines {
  margin-top: 0.3rem;
  position: relative;
}

.mobile_about_me_time_line_bg {
  width: 100vw;
  height: 0.08rem;
  background: rgba(107,161,255,0.1);
  position: absolute;
  top: 0.38rem;
}

.mobile_about_me_banner_time_lines_item {
  width: 1rem;
  height: 1rem;
  cursor: pointer;
  position: relative;
  margin: 0 1rem;
}

.mobile_about_me_timeline_line_dote_active {
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 0.4rem;
  background: #DDEBFF;
  border: 0.02rem solid rgba(50,123,251,0.5);
}

.mobile_about_me_timeline_line_dote {
  width: 0.6rem;
  height: 0.6rem;
  z-index: 9;
  background: #FFFFFF;
  border: 0.06rem solid #FFFFFF;
  border-radius: 0.3rem;
  position: absolute;
  left: 0.1rem;
  top: 0.1rem;
}

.mobile_about_me_banner_time_lines_item_dot {
  width: 0.4rem;
  height: 0.4rem;
  background: #5A96FF;
  border-radius: 0.2rem;
}

.mobile_about_me_banner_time_lines_item_title {
  width: max-content;
  font-size: 0.4rem;
  position: absolute;
  bottom: -0.8rem;
}

.mobile_about_us_goal_warp {
  width: 100vw;
}

.mobile_about_us_goal_card {
  width: 96vw;
  padding: 0.4rem;
  background: rgba(255,255,255,0.5);
  border-radius: 0.14rem;
  margin: 0.3rem 0;
  color: rgba(0,0,0,0.9);
  font-size: 0.4rem;
  text-align: justify;
}

.mobile_about_us_goal_title {
  font-size: 0.42rem;
  margin-top: 0.2rem;
  font-weight: 600;
}

.mobile_about_us_history {
  width: 100vw;
  height: auto;
}