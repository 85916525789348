.leave_info_toast_wrap {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.leave_info_toast_card {
  width: 8.89rem;
  height: 5.43rem;
  background: url('../../static/images/leave_info_toast_card_bg.png') no-repeat;
  background-color: #ffffff;
  background-size: 100%;
  border-radius: 0.2rem;
  box-shadow: 0 0.04rem 0.25rem 0 rgba(0,26,77,0.1);
  position: relative;
}

.leave_info_toast_close {
  width: 0.24rem;
  height: 0.24rem;
  cursor: pointer;
  position: absolute;
  right: 0.28rem;
  top: 0.28rem;
}

.leave_info_toast_header {
  display: flex;
  align-items: center;
  margin-left: 0.6rem;
  margin-top: 0.8rem;
}

.leave_info_toast_header_title {
  height: 0.48rem;
  font-family: Alibaba PuHuiTi 3.0, Alibaba PuHuiTi 30;
  font-weight: 800;
  font-size: 0.5rem;
  color: #102058;
  line-height: 0.48rem;
  letter-spacing: 0.02rem;
}

.leave_info_toast_header_desc {
  width: 2.54rem;
  height: 0.48rem;
  background-color: #ffffff;
  border-radius: 0.24rem;
  line-height: 0.48rem;
  text-align: center;
  font-weight: 500;
  font-size: 0.24rem;
  color: #2150A5;
  letter-spacing: 0.02rem;
}

.leave_info_toast_content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 0.6rem;
  margin-top: 0.6rem;
}

.leave_info_toast_content_left_item {
  height: 0.4rem;
  display: flex;
  align-items: center;
  font-size: 0.18rem;
  color: #2150A5;
  margin-top: 0.25rem;
}

.content_left_item_dote {
  width: 0.12rem;
  height: 0.12rem;
  background: #7DACFF;
  border-radius: 0.12rem;
}

.content_left_item_text {
  display: flex;
  align-items: baseline;
  font-size: 400;
  letter-spacing: 0.02rem;
}

.content_left_item_text_num {
  font-size: 0.25rem;
  font-weight: normal;
  margin: 0 0.05rem;
}

.leave_info_toast_content_right {
  width: 3.49rem;
}

.content_right_input {
  width: 3.49rem;
  height: 0.7rem;
  font-size: 0.2rem;
  font-family: PingFang SC, PingFang SC;
  color: #2150A5;
  background: #FFFFFF;
  box-shadow: 0 0 0.12rem 0 rgba(64,31,31,0.05);
  border-radius: 0.1rem;
  border: 0.01rem solid;
  border-image: linear-gradient(180deg, rgba(255, 255, 255, 0), rgba(188.4291985630989, 212.49710708856583, 255, 1)) 1 1;
  margin-bottom: 0.3rem;
  padding-left: 0.2rem;
  letter-spacing: 0.03rem;
}

input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #2150A5;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #2150A5;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #2150A5;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #2150A5;
}

.content_right_submit_button {
  width: 3.49rem;
  height: 0.7rem;
  background: linear-gradient( 90deg, #4588FF 0%, #206EF7 100%);
  border-radius: 0.13rem;
  line-height: 0.7rem;
  text-align: center;
  font-size: 0.24rem;
  color: #FFFFFF;
  letter-spacing: 0.04rem;
  cursor: pointer;
  margin-top: 0.2rem;
}

.leave_info_toast_success {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.leave_info_toast_success_icon {
  width: 1.2rem;
  height: 1.2rem;
}

.leave_info_toast_success_title {
  font-weight: 500;
  font-size: 0.26rem;
  color: #000000;
}

.leave_info_toast_success_tip {
  font-size: 0.2rem;
  color: #000000;
}

.leave_info_toast_success_desc {
  font-weight: 400;
  font-size: 0.2rem;
  color: rgba(0,0,0,0.6);
}

.leave_info_toast_content_number_warp {
  width: 3.9rem;
  height: 0.4rem;
  line-height: 0.4rem;
  background-color: rgba(240, 246, 255, 0.60);
  border-radius: 0.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.leave_info_toast_header_icon {
  width: 0.28rem;
  height: 0.28rem;
}

.leave_info_toast_content_title {
  font-weight: 400;
  font-size: 0.16rem;
  color: #000000;
}

.leave_info_toast_content_number {
  font-weight: 500;
  font-size: 0.16rem;
  color: #3775FF;
  letter-spacing: 0.01rem;
}