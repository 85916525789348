.mobile_info_toast_wrap {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mobile_info_toast_card {
  width: 7.49rem;
  height: 11.14rem;
  background: url('../../static/images/mobile_info_toast_card_bg.png') no-repeat;
  background-size: 100%;
  position: relative;
}

.mobile_info_toast_header {
  display: flex;
  align-items: center;
  margin-top: 2.3rem;
  margin-left: 0.5rem;
  margin-bottom: 0.4rem;
  color: #102058;
}

.mobile_info_toast_header_title {
  font-weight: 800;
  font-size: 0.5rem;
}

.mobile_info_toast_header_desc {
  font-size: 0.42rem;
}

.mobile_info_toast_content_left_item {
  display: flex;
  align-items: center;
  color: #2150A5;
  margin-left: 0.5rem;
  margin-top: 0.06rem;
  letter-spacing: 0.02rem;
}

.mobile_content_item_dote {
  width: 0.2rem;
  height: 0.2rem;
  background: #7DACFF;
  border-radius: 0.1rem;
}

.mobile_content_item_text {
  display: flex;
  align-items: baseline;
  font-size: 0.35rem;
}

.mobile_content_item_text_num {
  font-size: 0.45rem;
  margin: 0 0.1rem;
}

.mobile_info_toast_input_wrap {
  margin: 0 auto;
  margin-top: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mobile_info_toast_input {
  width: 7rem;
  height: 1rem;
  background: #FFFFFF;
  border-radius: 0.5rem;
  border: 0.01rem solid #DEE1E7;
  padding-left: 0.4rem;
  font-size: 0.4rem;
}

input::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #888888;
}

input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #888888;
}

input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #888888;
}

input:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #888888;
}

.mobile_info_toast_submit_btn {
  width: 7rem;
  height: 1rem;
  text-align: center;
  line-height: 1rem;
  border-radius: 0.5rem;
  background: #3A80FC;
  margin: 0 auto;
  margin-top: 0.5rem;
  color: #FFFFFF;
  font-size: 0.4rem;
  letter-spacing: 0.02rem;
}

.mobile_toast_close_icon {
  width: 1rem;
  height: 1rem;
  position: absolute;
  left: 0;
  right: 0;
  bottom: -1.5rem;
  margin: 0 auto;
}

.mobile_info_toast_success_title {
  font-weight: 500;
  font-size: 0.6rem;
  color: #000000;
  margin-top: 3.2rem;
}

.mobile_info_toast_success_tip {
  font-size: 0.43rem;
  color: #000000;
  margin-top: 0.6rem;
}

.mobile_info_toast_success_desc {
  font-weight: 400;
  font-size: 0.44rem;
  color: rgba(0,0,0,0.6);
  margin-top: 0.4rem;
}

.mobile_info_toast_content_number_warp {
  margin-top: 1.2rem;
}

.mobile_info_toast_header_icon {
  width: 1rem;
  height: 1rem;
}

.mobile_info_toast_content_title {
  font-weight: 400;
  font-size: 0.41rem;
  color: #000000;
  margin-top: 0.2rem;
}

.mobile_info_toast_content_number {
  font-weight: 500;
  font-size: 0.5rem;
  color: #3775FF;
  margin-top: 0.3rem;
}