.product {
  background-color: #fff;
}

.system_state {
  background-color: #EEF3FF;
  padding-bottom: 1.5rem;
}

.system_state_content {
  width: 9.9rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.system_state_item {
  width: 2.6rem;
  height: 2.5rem;
  margin-bottom: 0.8rem;
  flex-shrink: 0;
  position: relative;
}

.system_state_bg {
  width: 2.19rem;
  height: 2.13rem;
  position: absolute;
  z-index: 0;
}

.system_state_icon {
  width: 0.64rem;
  height: 0.64rem;
  z-index: 1;
}

.system_state_item_title {
  z-index: 1;
}

.system_state_item_desc {
  z-index: 1;
}

.state_collect {
  width: 14.84rem;
  height: 4.8rem;
  background: rgba(255,255,255,0.2);
  border-radius: 0.2rem;
  border: 0.02rem solid #FFFFFF;
}

.state_collect_card {
  width: 13.84rem;
  height: 3.8rem;
  background: rgba(255,255,255,0.4);
  border-radius: 0.2rem;
}

.state_collect_card_content {
  width: 12.3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.state_collect_card_item {
  width: 3.6rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

.state_dote {
  width: 0.18rem;
  height: 0.18rem;
  background: #00DF1D;
  border-radius: 0.09rem;
}

.state_dote_abnormality {
  background: #FF8000;
}

.international_certification_item {
  width: 3.26rem;
  height: 3.8rem;
  background: #FFFFFF;
  box-shadow: 0 0.03rem 0.2rem 0 rgba(0,20,55,0.15);
  border-radius: 0.12rem;
  margin: 0 0.22rem;
}

.international_certification_item_icon {
  width: 1.4rem;
  height: 1.4rem;
  margin-top: 0.57rem;
}